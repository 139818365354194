import { Profile } from './ProfileTypes';
import { UserProfile } from './UserProfileTypes';

export type InvoiceDetail = {
  clinicId: string;
  createdAt: string;
  items: Array<InvoiceItem>;
  id: number;
  patient: UserProfile;
  patientId: string;
  status: string;
  totalChargeAmount: number;
  totalItemsCount: number;
  type: string;
  updatedAt: string;
};

export type InvoicePrepaymentDetail = {
  clinicId: string;
  createdAt: string;
  items: Array<InvoiceItem>;
  id: number;
  patient: UserProfile;
  patientId: string;
  status: string;
  totalChargeAmount: number;
  totalItemsCount: number;
  type: string;
  updatedAt: string;
};

export type InvoiceItem = {
  id: number;
  icd10: number;
  cpt: number;
  chargeAmount: number;
  note: string;
  date: string;
  event: {
    id: string;
  };
  clinic: {
    id: string;
    name: string;
  };
  doctor: {
    id: string;
  };
};

export enum InvoiceHistoryOperation {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  CHANGE_STATUS = 'CHANGE_STATUS',
  ADD_ITEM = 'ADD_ITEM',
  UPDATE_ITEM = 'UPDATE_ITEM',
  DELETE_ITEM = 'DELETE_ITEM',
}

export type InvoiceShowHistory = {
  id: string;
  editorId: string;
  invoiceId: number;
  invoiceItemId: number;
  claimId: number;
  changeList: Array<{
    key: string;
    to: number[];
    from: number[];
  }>;
  operation: InvoiceHistoryOperation;
  createdAt: string;
  updatedAt: string;
  editor: {
    id: string;
    profile: {
      id: string;
      firstName: string;
      lastName: string;
    };
  };
};

export enum ClaimStatusType {
  CREATED = 'Created',
  VALIDATED = 'Validated',
  ERROR = 'Error',
  //HOLD = 'Hold',
  //QUEUE = 'Queue',
  RELEASED = 'Released',
  MODIFIED = 'Modified',
  DELETED = 'Deleted',
  ACKNOWLEDGED = 'Acknowledged',
  REJECTED = 'Rejected',
  PAID = 'Paid',
}

export enum ClaimTypeEnum {
  BLANK = 'Blank',
  SYSTEM = 'System',
  MANUAL = 'Manual',
  ON_THE_PATIENT_RECORDS = "On the Patient's records",
  ON_THE_PREVIOUS_CLAIM = 'On the previous Claim',
}

export type ClaimCardType = {
  id: number;
  insuredName: string;
  createdAt: string;
  status: string;
  patientAccountNumber: string;
  insurancePlanNameOrProgramName: string | null;
  patientName: string;
  paymentDate: null | string;
  totalCharge: number;
  clinic: {
    id: string;
    name: string;
  };
  claimType: ClaimTypeEnum;
  patient: {
    id: string;
    isHoldAppointment: any;
    isHoldBilling: any;
    phone: string;
    profile: {
      id: string;
      firstName: string;
      lastName: string;
    };
    avatar: {
      id: string;
      name: string;
      url: string;
    };
  };
  doctor: any;
  submitter: any;
};

export enum InvoicesMainType {
  PREPAYMENT = 'prePayment',
  SHEET = 'sheet',
  POSTPAYMENT = 'postPayment',
}

export type ItemInInvoiceSheetType = {
  id: number;
  icd: Array<{ code: string; type: string }> | null;
  cpt: string[] | null;
  chargeAmount: number[] | null;
  index: number;
  note: string | null;
  date: any | null;
  appointmentId: string | null;
  invoiceId: number;
  claimId: number;
  billingProviderId: string | null;
  billingProviderAsDoctorId: string | null;
  isUncorrectedDoctorIns: boolean;
  createdAt: Date;
  appointment: {
    id: string;
    authSigs: number;
    countManualClaims: number;
    mainVisitReason: string;
    countSystemClaims: number;
    date: string;
    visits: Array<{
      id: string;
      startTime: string;
      endTime: string;
      isCompletedDocuments: false;
      reason: string;
      visitLengthMinutes: number;
      specialistId: string;
    }>;
  } | null;
  clinic: {
    id: string;
    name: string;
    city: string;
    street: string;
  } | null;
  claim: {
    id: number;
    status: string;
    claimType: string;
    services: ServicesType[] | null;
  };
  billingProvider: { id: string; name: string } | null;
  doctor: UserInInvoiceItemType | null;
  billingProviderAsDoctor: UserInInvoiceItemType | null;
  billingLocation: { id: string; name: string } | null;
  billingLocationId: any | null;
  dateOfServices: string[] | null;
};

export type UserInInvoiceItemType = {
  id: string;
  isHoldAppointment: boolean | null;
  isHoldBilling: boolean | null;
  phone: string;
  profile: {
    id: string;
    firstName: string;
    lastName: string;
    cellPhone: string | null;
  };
  avatar: { id: string; name: string; url: string } | null;
};
export type ServicesType = {
  cpt: string | null;
  emg: string | null;
  epsdt: string | null;
  units: string | null;
  dateTo: any | null;
  idQual: string | null;
  charges: string | null;
  pointer: string | null;
  dateFrom: any | null;
  modifier: string[];
  placeOfService: string | null;
  renderingProviderId: string | null;
};
export type ClaimInvoiceSheetType = {
  id: number;
  payerId: string;
  clinicId: string;
  doctorId: string;
  patientId: string;
  submitterId: string;
  defaultToGroupHealthPlan: string;
  patientName: string;
  patientBirthDate: string;
  patientGender: string;
  patientAddress: string;
  patientCity: string;
  patientState: string;
  patientZip: string;
  patientPhone: string;
  isPatientEmployment: boolean;
  isPatientAutoAccident: boolean;
  isPatientOtherAccident: boolean;
  patientRelationshipToInsured: string;
  insuredIdNumber: string;
  insuredPayerId: string;
  insuredName: string | null;
  insuredBirthDate: string | null;
  insuredGender: string | null;
  insuredAddress: string | null;
  insuredCity: string | null;
  insuredState: string | null;
  insuredZip: string | null;
  insuredPhone: string | null;
  insurancePlanNameOrProgramName: string;
  otherClaimIdType: string | null;
  otherClaimId: string | null;
  insuredPolicyGroupFECANumber: string | null;
  isAnotherHealthBenefitPlan: boolean;
  reservedForNUCC: string | null;
  otherInsuredType: string | null;
  otherInsuredName: string | null;
  patientSignature: string;
  patientSignatureDate: string | null;
  insuredSignature: string;
  insuredSignatureDate: string | null;
  dateOfIllness: string | null;
  dateOfIllnessQual: string | null;
  otherDate: string | null;
  otherDateQual: string | null;
  unableToWorkStartDate: string | null;
  unableToWorkEndDate: string | null;
  nameOfReferringProviderType: string | null;
  nameOfReferringProvider: string | null;
  additionalClaimInfo: string | null;
  hospitalizationStartDate: string | null;
  hospitalizationEndDate: string | null;
  isOutsideLab: boolean;
  labCharges: number;
  typeICD: string;
  paymentDate: string | null;
  patientControlNumber: string | null;
  patientAccountNumber: string;
  state: string | null;
  insuredPolicyNumber: string;
  diagnosisCodes: string[] | null;
  resubmissionCode: string | null;
  originalRef: string | null;
  priorAuthorizationNumber: string | null;
  federalTaxId: string | null;
  isFederalSSN: boolean;
  isFederalEIN: boolean;
  isAcceptAssignment: boolean;
  totalCharge: number;
  totalPaid: number;
  isFacilityRendering: boolean;
  isFhysicanRendering: boolean;
  renderingName: string | null;
  renderingId: string | null;
  renderingNpi: string | null;
  serviceFacilityName: string | null;
  serviceFacilityId: string | null;
  serviceStreet: string | null;
  serviceOffice: string | null;
  serviceCity: string | null;
  serviceState: string | null;
  serviceZip: string | null;
  serviceNpi: string | null;
  isFacilityBilling: boolean;
  isFhysicanBilling: boolean;
  billingFacilityName: string | null;
  billingFacilityId: string | null;
  billingStreet: string | null;
  billingOffice: string | null;
  billingCity: string | null;
  billingState: string | null;
  billingZip: string | null;
  billingNpi: string | null;
  billingEin: string | null;
  status: string;
  claimType: string;
  Type: string | null;
  doctorInsuranceType: string;
  controlNumber: string | null;
  mdStatus: string | null;
  tradingPartnerServiceId: string | null;
  services: ServicesType[] | null;
  createdAt: string;
  updatedAt: string;
};
