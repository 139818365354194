import { MenuItem, RadioGroup, SelectChangeEvent } from '@mui/material';
import {
  TooltipDatePicker,
  TooltipInput,
  RadioButton,
  TooltipSelectInput,
} from 'components';
import * as S from '../../styles';
import { InjuryQual, OtherQual } from '../../config';
import { AlphabetLines } from '../AlphabetLines';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { ClaimValues, InjuryInfoFormType } from 'types/AppointmentNewTypes';
import {
  injuryClaimForm,
  injuryClaimSchema,
} from 'pages/Billing/components/Claims/formikClaim';
import React, { memo, useEffect } from 'react';
import { UserProviderType } from 'types/UserProfileTypes';
import { ClaimActionEnum } from '../../ClaimForm';

type Props = {
  data?: ClaimValues;
  getData: (value: any) => void;
  isSave: ClaimActionEnum | null;
  setIsSave: (value: ClaimActionEnum | null) => void;
  providerData?: UserProviderType | null;
  setIsDirty: (isDirty: boolean) => void;
  checkStatusCondition?: boolean;
};

export const InjuryBlock = memo(
  ({
    checkStatusCondition,
    data,
    getData,
    isSave,
    setIsSave,
    providerData,
    setIsDirty,
  }: Props) => {
    const injuryFormik = useFormik<Partial<InjuryInfoFormType>>({
      initialValues: injuryClaimForm(data, providerData),
      validateOnChange: false,
      enableReinitialize: true,
      validationSchema: injuryClaimSchema,
      onSubmit: values => {
        console.log(values);
      },
    });

    useEffect(() => {
      if (isSave) {
        (async () => {
          const errors = await injuryFormik.validateForm();
          if (!Object.keys(errors).length) {
            getData(injuryFormik.values);
          } else setIsSave(null);
        })();
      }
    }, [isSave]);

    useEffect(() => {
      if (injuryFormik.dirty) {
        setIsDirty(true);
      }
    }, [injuryFormik.dirty]);

    return (
      <>
        <S.GrayBlockWrapper>
          <S.Line>
            <S.InputWrapper>
              <TooltipDatePicker
                label="14. Date of Current Illness, Injury, or Pregnancy"
                value={dayjs(injuryFormik.values.dateOfIllness)}
                disabled={checkStatusCondition}
                onChange={date => injuryFormik.setFieldValue('dateOfIllness', date)}
                error={!!injuryFormik.errors.dateOfIllness}
                tooltipHelperText={injuryFormik.errors.dateOfIllness}
                slotProps={{ field: { clearable: !!injuryFormik.values.dateOfIllness } }}
              />
            </S.InputWrapper>
            <S.InputWrapper className="px180">
              <TooltipSelectInput
                value={injuryFormik.values.dateOfIllnessQual}
                onChange={(e: SelectChangeEvent<unknown>) =>
                  injuryFormik.setFieldValue('dateOfIllnessQual', e.target.value)
                }
                label="14a Qual."
                disabled={checkStatusCondition}
                renderValue={(selected: any) => selected}
                error={!!injuryFormik.errors.dateOfIllnessQual}
                tooltipHelperText={injuryFormik.errors.dateOfIllnessQual}
              >
                {InjuryQual.map(item => {
                  return (
                    <MenuItem value={item.value} key={item.value}>
                      <S.MenuItemContent>{item.name}</S.MenuItemContent>
                    </MenuItem>
                  );
                })}
              </TooltipSelectInput>
            </S.InputWrapper>
            <S.InputWrapper>
              <TooltipDatePicker
                label="15. Other Date"
                disabled={checkStatusCondition}
                value={dayjs(injuryFormik.values.otherDate)}
                onChange={date => injuryFormik.setFieldValue('otherDate', date)}
                slotProps={{ field: { clearable: !!injuryFormik.values.otherDate } }}
              />
            </S.InputWrapper>
            <S.InputWrapper className="px180">
              <TooltipSelectInput
                value={injuryFormik.values.otherDateQual}
                onChange={(e: SelectChangeEvent<unknown>) =>
                  injuryFormik.setFieldValue('otherDateQual', e.target.value)
                }
                label="15a Qual."
                disabled={checkStatusCondition}
                renderValue={(selected: any) => selected}
              >
                {OtherQual.map(item => {
                  return (
                    <MenuItem value={item.value} key={item.value}>
                      <S.MenuItemContent>{item.name}</S.MenuItemContent>
                    </MenuItem>
                  );
                })}
              </TooltipSelectInput>
            </S.InputWrapper>
          </S.Line>

          <S.Line>
            <S.Label>
              <S.LabelArticle>
                16. Dates patient unable to work in current occupation
              </S.LabelArticle>
              <S.Line>
                <S.InputWrapper className="px180">
                  <TooltipDatePicker
                    label=""
                    disabled={checkStatusCondition}
                    value={dayjs(injuryFormik.values.unableToWorkStartDate)}
                    onChange={date =>
                      injuryFormik.setFieldValue('unableToWorkStartDate', date)
                    }
                    slotProps={{
                      field: { clearable: !!injuryFormik.values.unableToWorkStartDate },
                    }}
                  />
                </S.InputWrapper>
                -
                <S.InputWrapper className="px180">
                  <TooltipDatePicker
                    label=""
                    disabled={checkStatusCondition}
                    value={dayjs(injuryFormik.values.unableToWorkEndDate)}
                    onChange={date =>
                      injuryFormik.setFieldValue('unableToWorkEndDate', date)
                    }
                    slotProps={{
                      field: { clearable: !!injuryFormik.values.unableToWorkEndDate },
                    }}
                  />
                </S.InputWrapper>
              </S.Line>
            </S.Label>

            <S.Label>
              <S.LabelArticle>
                17. Name of referring provider or other source
              </S.LabelArticle>
              <S.Line>
                <S.ConnectLine>
                  <S.InputWrapper className="px90">
                    <TooltipSelectInput
                      label=""
                      disabled={checkStatusCondition}
                      value={injuryFormik.values.nameOfReferringProviderType}
                      onChange={(e: SelectChangeEvent<unknown>) =>
                        injuryFormik.setFieldValue(
                          'nameOfReferringProviderType',
                          e.target.value,
                        )
                      }
                    >
                      <MenuItem value={'DN'}>
                        <S.MenuItemContent>DN</S.MenuItemContent>
                      </MenuItem>
                      <MenuItem value={'DK'}>
                        <S.MenuItemContent>DK</S.MenuItemContent>
                      </MenuItem>
                      <MenuItem value={'DQ'}>
                        <S.MenuItemContent>DQ</S.MenuItemContent>
                      </MenuItem>
                    </TooltipSelectInput>
                  </S.InputWrapper>
                  <S.InputWrapper>
                    <TooltipInput
                      disabled={checkStatusCondition}
                      label=""
                      placeholder="Name of reffering provider"
                      value={injuryFormik.values.nameOfReferringProvider}
                      onChange={e =>
                        injuryFormik.setFieldValue(
                          'nameOfReferringProvider',
                          e.target.value,
                        )
                      }
                    />
                  </S.InputWrapper>
                </S.ConnectLine>
              </S.Line>
            </S.Label>

            <S.InputWrapper>
              <TooltipInput
                label="NPI"
                disabled={checkStatusCondition}
                value={injuryFormik.values.referringProviderNpi}
                onChange={e =>
                  injuryFormik.setFieldValue('referringProviderNpi', e.target.value)
                }
              />
            </S.InputWrapper>

            <S.Line className={'withoutLabel'}>
              <S.InputWrapper className="px180">
                <TooltipDatePicker
                  label=""
                  disabled={checkStatusCondition}
                  value={dayjs(injuryFormik.values.hospitalizationStartDate)}
                  onChange={date =>
                    injuryFormik.setFieldValue('hospitalizationStartDate', date)
                  }
                  slotProps={{
                    field: { clearable: !!injuryFormik.values.hospitalizationStartDate },
                  }}
                />
              </S.InputWrapper>

              <S.InputWrapper className="px180">
                <TooltipDatePicker
                  label=""
                  disabled={checkStatusCondition}
                  value={dayjs(injuryFormik.values.hospitalizationEndDate)}
                  onChange={date =>
                    injuryFormik.setFieldValue('hospitalizationEndDate', date)
                  }
                  slotProps={{
                    field: { clearable: !!injuryFormik.values.hospitalizationEndDate },
                  }}
                />
              </S.InputWrapper>
            </S.Line>
          </S.Line>

          <S.Line>
            <RadioGroup
              value={injuryFormik.values.isOutsideLab}
              onChange={e =>
                injuryFormik.setFieldValue('isOutsideLab', e.target.value === 'true')
              }
            >
              <S.RadioItem>
                <S.Label>
                  <S.LabelArticle>20. Outside Lab?</S.LabelArticle>
                </S.Label>
                <S.RadioButtons>
                  <RadioButton label="Yes" value={true} disabled={checkStatusCondition} />
                  <RadioButton label="No" value={false} disabled={checkStatusCondition} />
                </S.RadioButtons>
              </S.RadioItem>
            </RadioGroup>
            <S.InputWrapper className="px180">
              <TooltipInput
                label={'Charges'}
                type="number"
                disabled={checkStatusCondition}
                isSum="$"
                value={injuryFormik.values.labCharges}
                onChange={e => injuryFormik.setFieldValue('labCharges', e.target.value)}
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <TooltipInput
                label="19. Additional Claim Information"
                disabled={checkStatusCondition}
                value={injuryFormik.values.additionalClaimInfo}
                onChange={e =>
                  injuryFormik.setFieldValue('additionalClaimInfo', e.target.value)
                }
                error={!!injuryFormik.errors.additionalClaimInfo}
                tooltipHelperText={injuryFormik.errors.additionalClaimInfo}
              />
            </S.InputWrapper>
          </S.Line>

          <AlphabetLines
            injuryFormik={injuryFormik}
            checkStatusCondition={checkStatusCondition}
          />
        </S.GrayBlockWrapper>
      </>
    );
  },
);
