import { FormikProvider, useFormik } from 'formik';
import AlertItem from 'components/atoms/Alert';
import AppointmentInformationBlock from './components/AppointmentInformationBlock';
import PatientInformationBlock from './components/PatientInformation';
import * as S from './styles';
import * as yup from 'yup';
import { UserProfile } from 'types/UserProfileTypes';
import AppointmentFooter from './components/AppointmentFooter';
import AppointmentPayment from './components/AppointmentPayment';
import AppointmentHistory from './components/AppointmentHistory';
import { useEffect, useState } from 'react';
import { AppointmentsNew, Visits } from 'types/AppointmentNewTypes';
import dayjs, { Dayjs } from 'dayjs';
import UserStatusPin from 'components/atoms/UserStatusPin';
import { useAppSelector } from 'hooks/useAppSelector';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { setMessage } from 'store/slices/message';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  useAppointmentCreateMutation,
  useAppointmentUpdateMutation,
} from 'store/api/appointment/appointmentApi';
import { dataForCRUD } from './dataForCRUD';
import { Dialog } from '@mui/material';
import SuccessDialog from 'components/molecules/SuccessDialog';
import { useGetClinicWorkingTimeMutation } from 'store/api/clinic-schedule/clinicScheduleApi';
import CircularLoader from 'components/atoms/CircuralLoader';
import { emailValidator, MORE_ONE, phoneValidator } from 'constants/constants';

export type AppointmentFormValues = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  patientNote: string;
  userId: string;
  isPossibleToUpdate: boolean;
  clinicId: string;
  typeServiceIdes: string[];
  visits: Array<Partial<Visits>>;
  date: string | Dayjs;
  mainCoveredService: string;
  mainCoveredServiceType: string;
  repeat: number;
  daysBetween: number | null;
  note: string;
  paymentMethod: string;
  cashSum: string;
  coupon: string;
  status: string;
  globalNote: string;
  discountText: string;
  isReevaluation: boolean;
  cashNote: string;
  dateOfBirth: string | Dayjs;
  // dateOfService: string | Dayjs;
  authSigs: number;
};

type Props = {
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  byClick?: boolean;
  isLoading?: boolean;
  recordTime?: {
    date?: string;
    time?: string;
  };
};

const validationSchema = (selectedAppointment?: AppointmentsNew) => {
  return yup.object().shape({
    firstName: yup.string().required('Is required'),
    lastName: yup.string().required('Is required'),
    phone: phoneValidator,
    email: emailValidator,
    date: yup.string().required('Is required'),
    clinicId: yup.string().required('Is required'),
    repeat: selectedAppointment ? yup.number() : yup.number().required('Is required'),
    daysBetween: yup.number().when('repeat', {
      is: (repeat: number) => Number(repeat) > 0,
      then(schema) {
        return schema.min(1, 'Select number of days to repeat');
      },
    }),
    visits: yup.array().of(
      yup.object().shape({
        typeServiceId: yup.string().required('Is required'),
        startTime: yup.string().required('Is required'),
        visitLengthMinutes: yup.number().min(1, MORE_ONE),
        specialistId: yup.string(),
      }),
    ),
    dateOfBirth: yup.string().required('Is required'),
    //dateOfService: yup.string(),
    typeServiceIdes: yup.array().of(yup.string()).min(1, 'Is required'),
    paymentMethod: yup.string().nullable(),
    mainCoveredService: yup.string().nullable(),
    mainCoveredServiceType: yup.string().when('mainCoveredService', {
      is: (value: string | null | undefined): boolean => !!value && value !== '',
      then: () => yup.string().required('Is required'),
      otherwise: () => yup.string().nullable(),
    }),
  });
};

const AppointmentDrawer: React.FC<Props> = ({
  userProfile,
  selectedAppointment,
  duplAppointment,
  recordTime,
  byClick,
  isLoading,
}) => {
  const dispatch = useAppDispatch();
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [createAppointment, createAppointmentStatus] = useAppointmentCreateMutation({});
  const [updateAppointment] = useAppointmentUpdateMutation({});
  const [checkWorkTime] = useGetClinicWorkingTimeMutation({});

  const handleSubmitBtn = () => {
    if (!formik.values.mainCoveredService) {
      formik.setFieldValue('mainCoveredService', '');
      formik.setFieldValue('mainCoveredServiceType', '');
    }
    dispatch(
      showDrawer({
        show: false,
        mode: DrawerMode.DEFAULT,
        props: null,
      }),
    );
    if (!selectedAppointment || duplAppointment) {
      createAppointment(dataForCRUD(formik, 'create'))
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Appointment was successfully created',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    } else {
      updateAppointment({
        id: selectedAppointment.id,
        ...dataForCRUD(formik, 'update'),
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Appointment was successfully updated',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    }
  };

  const formik = useFormik<AppointmentFormValues>({
    initialValues: {
      firstName: '',
      userId: '',
      mainCoveredService: '',
      mainCoveredServiceType: '',
      isReevaluation: false,
      lastName: '',
      isPossibleToUpdate: false,
      phone: '',
      dateOfBirth: '',
      globalNote: '',
      email: '',
      patientNote: '',
      visits: [],
      status: '',
      authSigs: 0,
      clinicId: '',
      typeServiceIdes: [],
      date: '',
      repeat: 0,
      daysBetween: 0,
      note: '',
      paymentMethod: '',
      cashSum: '',
      coupon: '',
      cashNote: '',
      discountText: '',
      // dateOfService: '',
    },
    validateOnChange: false,
    validationSchema: validationSchema(selectedAppointment),
    onSubmit: () => {
      checkWorkTime({
        clinicId: selectedClinic.id,
        date: dayjs(formik.values.date).format('YYYY-MM-DD'),
      })
        .unwrap()
        .then(res => {
          res.isWorkDay ? handleSubmitBtn() : setSuccessAppointment(true);
        })
        .catch(error => {
          dispatch(
            showDrawer({
              show: false,
              mode: DrawerMode.DEFAULT,
              props: null,
            }),
          );
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    },
  });

  useEffect(() => {
    if (selectedAppointment) {
      formik.setValues({
        ...formik.values,
        firstName: selectedAppointment.patient?.profile?.firstName || '',
        lastName: selectedAppointment.patient?.profile?.lastName || '',
        phone: selectedAppointment.patient?.phone || '',
        email: selectedAppointment.patient?.email || '',
        patientNote: selectedAppointment.patientNote || '',
        clinicId: selectedAppointment.clinicId || '',
        visits:
          selectedAppointment.visits?.map(visit => {
            return {
              id: visit.id,
              typeServiceId: visit.typeServiceId,
              startTime: visit.startTime.slice(0, 5),
              visitLengthMinutes: visit.visitLengthMinutes,
              specialistId: visit.specialistId || '',
            };
          }) || [],
        typeServiceIdes: selectedAppointment.visits.map(visit => visit.typeServiceId),
        date: selectedAppointment.date || '',
        authSigs: selectedAppointment.authSigs || 0,
        globalNote: selectedAppointment.globalNote || '',
        note: selectedAppointment.note || '',
        isReevaluation: selectedAppointment.isReevaluation || false,
        paymentMethod: selectedAppointment.paymentMethod || '',
        cashSum: selectedAppointment.cashSum ?? '',
        coupon: selectedAppointment.coupon?.value ?? '',
        userId: selectedAppointment.patient?.id ?? '',
        mainCoveredService: selectedAppointment.mainVisitReason ?? '',
        mainCoveredServiceType: selectedAppointment.mainVisitReasonType ?? '',
        status: selectedAppointment.status ?? '',
        cashNote: selectedAppointment.cashNote ?? '',
        dateOfBirth: selectedAppointment.patient?.profile?.dateOfBirth
          ? dayjs(selectedAppointment.patient?.profile?.dateOfBirth)
          : '',
        // dateOfService: selectedAppointment?.dateOfService
        //   ? dayjs(selectedAppointment?.dateOfService)
        //   : '',
      });
    }
  }, [selectedAppointment]);

  useEffect(() => {
    if (duplAppointment) {
      formik.setValues({
        ...formik.values,
        firstName: duplAppointment.patient?.profile?.firstName || '',
        lastName: duplAppointment.patient?.profile?.lastName || '',
        phone: duplAppointment.patient?.phone || '',
        email: duplAppointment.patient?.email || '',
        clinicId: duplAppointment.clinicId || '',
        userId: duplAppointment.patient?.id || '',
        paymentMethod: duplAppointment.paymentMethod || '',
        dateOfBirth: duplAppointment.patient?.profile?.dateOfBirth || '',
      });
    }
  }, [duplAppointment]);

  useEffect(() => {
    if (recordTime && recordTime.date && recordTime.time) {
      formik.setValues({
        ...formik.values,
        clinicId: selectedClinic.id,
        date: recordTime.date,
      });
    }
  }, [recordTime]);

  const [successAppointment, setSuccessAppointment] = useState(false);

  return (
    <S.Wrapper>
      <Dialog
        open={successAppointment}
        onClose={() => setSuccessAppointment(!successAppointment)}
      >
        <SuccessDialog
          helperText="Are you sure, that you want to request an appointment?"
          successText="Continue"
          onClose={() => {
            dispatch(
              showDrawer({
                show: false,
                mode: DrawerMode.DEFAULT,
                props: null,
              }),
            );
            setSuccessAppointment(!successAppointment);
          }}
          titleText="
          The selected day is a holiday"
          onSuccess={() => {
            handleSubmitBtn();
          }}
        />
      </Dialog>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <FormikProvider value={formik}>
          <S.Content>
            <S.HeaderContent>
              <S.ArticleWrap>
                <S.Article>
                  {selectedAppointment ? (
                    <>
                      {'Appointment #' + selectedAppointment?.id?.slice(0, 8)}{' '}
                      <UserStatusPin type={selectedAppointment.status} />
                    </>
                  ) : (
                    'Add an Appointment'
                  )}
                </S.Article>
              </S.ArticleWrap>
              <S.AlertWrapper>
                <AlertItem
                  $isHideButton
                  text={
                    selectedAppointment
                      ? 'In case you want to change some disabled fields in appointment details, please cancel current request and create new one'
                      : 'Before you save the form, please make sure that your insurance form is filled'
                  }
                  type="info"
                />
              </S.AlertWrapper>
            </S.HeaderContent>
            <S.ScrollContainer>
              {isLoading ? (
                <CircularLoader color="#0084B1" size={24} />
              ) : (
                <>
                  <PatientInformationBlock
                    byClick={byClick}
                    formik={formik}
                    userProfile={userProfile}
                    selectedAppointment={selectedAppointment}
                    duplAppointment={duplAppointment}
                  />
                  <AppointmentInformationBlock
                    formik={formik}
                    userProfile={userProfile}
                    selectedAppointment={selectedAppointment}
                    duplAppointment={duplAppointment}
                    recordTime={recordTime}
                  />
                  {formik.values.clinicId && (
                    <AppointmentPayment
                      formik={formik}
                      userProfile={userProfile}
                      selectedAppointment={selectedAppointment}
                    />
                  )}
                  {selectedAppointment && (
                    <AppointmentHistory selectedAppointment={selectedAppointment} />
                  )}
                </>
              )}
            </S.ScrollContainer>
          </S.Content>
          <AppointmentFooter
            formik={formik}
            selectedAppointment={selectedAppointment}
            duplAppointment={duplAppointment}
          />
        </FormikProvider>
      </form>
    </S.Wrapper>
  );
};

export default AppointmentDrawer;
