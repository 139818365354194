import styled from 'styled-components';
import { devices } from '../../../constants/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  //height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  height: 100%;

  @media ${devices.xs} {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`;

export const SubtitleItems = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

export const SearchWrapper = styled.div`
  width: 394px;

  @media ${devices.xs} {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  width: 210px;

  @media ${devices.xs} {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const SubtitleText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;

  @media ${devices.xs} {
  }
`;

export const SubtitleBar = styled.div`
  height: 62px;
  margin-left: 22px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${devices.xs} {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: center;
    height: auto;

    @media ${devices.xs} {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
`;

export const Information = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  max-height: 100vh;
  flex: 1;
  flex-direction: column;
  background: #fff;

  @media ${devices.xs} {
    /* height: none; */
  }
`;
