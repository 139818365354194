import styled from '@emotion/styled';
import { devices } from '../../../../../constants/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
`;

export const Information = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  /* max-height: calc(100vh - 10px); */
  flex: 1;
  flex-direction: column;
  /* height: 100%; */
  background: #fff;

  @media ${devices.xs} {
    /* height: none; */
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  gap: 10px;
  overflow-y: hidden;
  overflow-x: hidden;
  height: calc(100vh - 165px);

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  height: calc(100vh - 215px);
  overflow: auto;

  @media ${devices.xs} {
    height: calc(100vh - 530px);
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;

export const TimelineWrapper = styled.div`
  font-family: Inter;
  font-size: 12px;
  color: var(--icon, #848a9b);
  height: 100%;

  & ::-webkit-scrollbar {
    width: 20px;
  }

  & ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  & ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  & .rbc-time-header-content {
    background: #e7e8ec;
    font-size: 14px;

    font-family: Inter;

    & .rbc-button-link {
      padding-top: 3px;
      font-size: 12px;
      font-weight: 500;
      color: var(--text, #202e5f);
    }
  }

  .react-calendar-timeline .rct-scroll {
    overflow-x: hidden !important;
  }

  & .rbc-today {
    background: inherit;
  }

  & .rbc-time-gutter.rbc-time-column {
    & .rbc-time-slot.slotDefault {
      background-color: #ffffff !important;
    }
  }

  & .rbc-time-slot.slotDefault {
    background-color: #fdebeb;
  }

  & .rbc-day-bg.slotDefault {
    background-color: #fdebeb;
  }

  .rbc-timeslot-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    min-height: 100px;
  }

  .rbc-time-view .rbc-row {
    min-height: 40px;
    background: #e7e8ec;
  }

  /* & .rbc-addons-dnd-row-body {
    max-height: 50px;
  } */

  /* & .rbc-addons-dnd-row-body {

  } */
  /* & .rbc-month-view {
    height: 100vh;
  } */
  & .rbc-day-bg {
    display: flex;
    flex-direction: column;
    min-height: 50px;
  }

  & .rbc-allday-cell {
    display: none;
  }

  & .rbc-off-range-bg {
    height: none;
  }

  & .rbc-row-segment {
    display: flex;
    justify-content: center;
  }

  & .rbc-show-more {
    user-select: none;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 12.696px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: #848a9b;
    width: 32px;
    height: 32px;
    border-radius: 24px;
    color: #fff;
  }

  /* & .rbc-row-segment {
    width: 24px;
    height: 24px;
    border-radius: 24px;
  } */

  & .rbc-event {
    padding: 0px !important;
    margin-bottom: 4px;
    border: none;
    background: none;
    height: 30px;
    &:focus {
      outline: none;
    }
    /* &:hover {
      width: 100% !important;
      left: 0 !important;
    } */
    /* :hover {
      width: 100%;
      left: 0px;
    } */
  }

  & .rbc-event-content {
    height: 30px;
    overflow: unset;
  }

  /* .rbc-row-content .rbc-row:nth-child(n + 3) {
    display: none;
  } */

  & .rbc-event-label {
    display: none;
  }

  .rbc-ellipsis,
  .rbc-show-more,
  .rbc-row-segment,
  .rbc-event-label {
    overflow-y: unset;
  }

  & .rbc-day-slot .rbc-event {
    overflow-y: unset;
  }

  & .rbc-header {
    pointer-events: none;
    display: flex;
    justify-content: center;
  }

  .rbc-header + .rbc-header {
    border-left: 1px solid #fff;
  }

  & .rbc-events-container {
    margin: 0;
  }

  & .rbc-date-cell {
    & .rbc-button-link {
      pointer-events: none;
    }
  }

  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-event {
    overflow: unset;
  }

  .rbc-time-header-content .rbc-button-link {
    font-weight: 600;
  }
`;
