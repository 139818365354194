import styled from 'styled-components';
import { devices } from 'constants/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 10px;
`;

export const HeaderLine = styled.div`
  height: 38px;
  min-height: 38px;
  width: 100%;
  background: #e7e8ec;
  display: flex;
  align-items: center;
`;

export const HeaderTextTime = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  width: 120px;
  height: 100%;
`;

export const TimeText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const TextBtn = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  width: 40px;
  height: 100%;
`;

export const AppointmentsText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const PlannerItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  height: calc(100vh - 215px);
  overflow: auto;

  @media ${devices.xs} {
    height: calc(100vh - 530px);
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;

export const TimeLine = styled.div<{ $isActive: boolean; $isWorkDay: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid var(--line, #d7d7d7);

  background: ${props => (props.$isWorkDay ? '#fff' : '#FDEBEB')};
  background: ${props => props.$isActive && '#EBF6F9 !important'};
`;

export const TimeColumn = styled.div`
  min-width: 120px;
  width: 120px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 100%;

  &:hover {
    background-color: #ebf5f9;
  }

  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
  }
`;

export const Records = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding-top: 5px;
`;

export const AddRecord = styled.div`
  width: 100%;
  min-height: 48px;
  /* border: 1px solid red;
  box-sizing: border-box; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* cursor: pointer; */
`;
