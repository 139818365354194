import * as S from './styles';

type CustomRemovedBoxType = {
  title: string;
  id: string;
  onDeleteClick?: (id: string) => void;
};

export const CustomRemovedBox = ({ id, title, onDeleteClick }: CustomRemovedBoxType) => {
  return (
    <S.CustomBox>
      <span>{title}</span>
      {onDeleteClick && <span onClick={() => onDeleteClick(id)}>&#10006;</span>}
    </S.CustomBox>
  );
};
