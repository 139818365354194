import { TextField, TextFieldProps } from '@mui/material';
import * as S from './styles';

type Props = { label?: string } & TextFieldProps;

const InputCellNumber: React.FC<Props> = ({ onChange, ...props }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value || /^\d*\.?\d*$/.test(value)) {
      onChange?.(e);
    }
  };

  return (
    <S.Wrapper>
      <TextField
        variant="standard"
        {...props}
        onChange={handleInputChange}
        sx={{ width: '100%' }}
        inputProps={{
          min: 0,
          ...props.inputProps,
        }}
      />
    </S.Wrapper>
  );
};

export default InputCellNumber;
