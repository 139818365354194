import React, { useEffect, useState } from 'react';
import { useLazyGetCptQuery } from 'store/api/claims/claimsApi';
import * as S from './styles';
import { CustomTable, RadioButton } from 'components';
import Button from 'components/atoms/Button';
import { TableCell, TableRow } from '@mui/material';
import { CptType } from 'types/ServicesType';

const headers = [
  { name: '', key: 'checkbox' },
  { name: 'Code', key: 'code' },
  { name: 'Description', key: 'description' },
];

type Props = {
  onApplyClick: (code: CptType[]) => void;
  currentValue?: CptType[];
  title?: string;
};

export const ChooseCptCodeMultiple = ({
  onApplyClick,
  currentValue,
  title = 'Choose HCPCS/CPT Code',
}: Props) => {
  const [getData, statusCpt] = useLazyGetCptQuery({});
  const [selectedItems, setSelectedItems] = useState<CptType[]>([]);

  useEffect(() => {
    getData({});
  }, [getData]);

  useEffect(() => {
    if (currentValue?.length) {
      setSelectedItems(
        statusCpt.data?.filter(item =>
          currentValue?.map(i => i.code)?.includes(item?.code),
        ) ?? [],
      );
    }
  }, [statusCpt]);

  return (
    <S.Content>
      <S.Article>{title}</S.Article>
      <S.Table className={'all'}>
        <CustomTable headers={headers}>
          {!!statusCpt.data?.length &&
            statusCpt.data?.map(row => (
              <TableRow
                key={row?.code}
                sx={{
                  '& .MuiTableCell-root': {
                    background: selectedItems?.some(item => item.code === row?.code)
                      ? '#e7e8ec'
                      : '',
                  },
                }}
              >
                <TableCell>
                  <RadioButton
                    onClick={() =>
                      selectedItems?.some(item => item.code === row?.code)
                        ? setSelectedItems(prev => prev.filter(i => i.code !== row?.code))
                        : setSelectedItems(prev => [...prev, row])
                    }
                    checked={selectedItems?.some(item => item.code === row?.code)}
                  />
                </TableCell>
                <TableCell>
                  <S.Text>{row?.code}</S.Text>
                </TableCell>
                <TableCell>
                  <S.Text>{row?.desc}</S.Text>
                </TableCell>
              </TableRow>
            ))}
        </CustomTable>
      </S.Table>

      <S.ButtonWrapper>
        <Button text="Apply" onClick={() => onApplyClick(selectedItems ?? '')} />
      </S.ButtonWrapper>
    </S.Content>
  );
};
