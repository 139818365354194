import * as yup from 'yup';
import { TOO_LONG } from 'constants/constants';
import { CptType } from 'types/ServicesType';

export type FormValues = {
  serviceName: string;
  clinicIds: string[];
  color: number;
  bg: number;
  isUseForFirstTimeVisit: boolean;
  firstVisitColor?: number;
  firstVisitBg?: number;
  defaultServiceTime: number;
  serviceCategory: string;
  isMassageType?: boolean;
  cptCodes: CptType[];
};

export enum SERVICE_ASSIGN {
  DOCTOR = 'doctor',
  SPECIALIST = 'specialist',
}

export const Schema = yup.object().shape({
  serviceName: yup.string().trim().max(50, TOO_LONG).required('Is required'),
  color: yup.number().required('Is required'),
  serviceCategory: yup.string().trim().max(50, TOO_LONG).required('Is required'),
});

export const headers = [{ name: 'Clinics', key: 'clinics' }];
