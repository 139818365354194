import { FormikProps } from 'formik';
import { AppointmentFormValues } from './AppointmentDrawer';
import dayjs from 'dayjs';
import { AppointmentStatus } from 'types/StatusTypes';

export const dataForCRUD = (formik: FormikProps<AppointmentFormValues>, type: string) => {
  const userData = {
    firstName: formik.values.firstName,
    lastName: formik.values.lastName,
    phone: formik.values.phone,
    email: formik.values.email,
  };

  const commonData = {
    paymentMethod: formik.values.paymentMethod,
    clinicId: formik.values.clinicId,
    isReevaluation: formik.values.isReevaluation,
    date: dayjs(formik.values.date).format('YYYY-MM-DD'),
    note: formik.values.note,
    patientNote: formik.values.patientNote,
    authSigs: formik.values.authSigs,
    ...(formik.values.mainCoveredService && {
      mainVisitReason: formik.values.mainCoveredService,
    }),
    ...(formik.values.mainCoveredServiceType && {
      mainVisitReasonType: formik.values.mainCoveredServiceType,
    }),
    // dateOfService: formik.values.dateOfService
    //   ? dayjs(formik.values.dateOfService).format('YYYY-MM-DD')
    //   : null,
  };

  const createData = {
    userData,
    ...commonData,
    repeatCount: Number(formik.values.repeat),
    ...(formik.values.repeat && {
      daysBetweenVisits: formik.values.daysBetween,
    }),

    visits: formik.values.visits.map(visit => ({
      typeServiceId: visit.typeServiceId,
      startTime: visit.startTime,
      visitLengthMinutes: visit.visitLengthMinutes,
      specialistId: visit.specialistId || undefined,
    })),
    globalNote:
      formik.values.patientNote && formik.values.patientNote.length > 0
        ? `Patient note: ${formik.values.patientNote}\n${formik.values.note}`
        : `${formik.values.note}`,
    ...(formik.values.coupon && { couponNumber: formik.values.coupon }),
  };

  const updateData = {
    userData,
    ...commonData,
    visits: formik.values.visits.map(visit => ({
      ...(visit.id && { id: visit.id }),
      typeServiceId: visit.typeServiceId,
      startTime: visit.startTime,
      visitLengthMinutes: visit.visitLengthMinutes,
      specialistId: visit.specialistId || null,
    })),
    cashSum: Number(formik.values.cashSum),
    cashNote: formik.values.cashNote,
    globalNote:
      formik.values.status === AppointmentStatus.PENDING
        ? formik.values.patientNote && formik.values.patientNote.length > 0
          ? `Patient note: ${formik.values.patientNote}; \n${formik.values.note}`
          : `${formik.values.note}`
        : formik.values.globalNote,
    ...(formik.values.coupon &&
      formik.values.discountText && { couponNumber: formik.values.coupon }),
    status: formik.values.status,
    isPossibleToUpdate: false,
    mainVisitReason: formik.values.mainCoveredService || '',
    mainVisitReasonType: formik.values.mainCoveredServiceType || '',
  };

  switch (type) {
    case 'create':
      return createData;
    case 'update':
      return updateData;
    default:
      return;
  }
};
