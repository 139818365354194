import styled from 'styled-components';
import { devices } from '../../../constants/device';

export const HorizontalHeaderRoot = styled.div<{ isPatient: boolean }>`
  border-bottom: 1px solid #efefef;
  background: #fff;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  @media ${devices.xs} {
    background: none;
    justify-content: ${props => !props.isPatient && 'flex-start'};
  }
`;

export const Animation = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;

  img {
    height: 30px;
    transition: left 5s cubic-bezier(0, 0, 1, 1);
  }
`;

export const SelectInputWrapper = styled.div`
  width: auto;
  min-width: 340px;
  margin-right: 45px;

  @media ${devices.xs} {
    min-width: 220px;
    width: 220px;
    margin-right: 10px;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  margin-right: 35px;
  //width: 100%;

  @media ${devices.xs} {
    margin-right: 0px;
    justify-content: center;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  & svg {
    width: 30px;
    height: 30px;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    object-fit: cover;
  }

  /*@media ${devices.xs} {
    display: none;
  }*/
`;

export const SVGItems = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Username = styled.div`
  color: var(--text, #202534);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const EnveloperWrapper = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
`;

export const Dot = styled.div`
  position: absolute;
  right: -3px;
  top: -3px;
  border-radius: 6px;
  width: 6px;
  height: 6px;
  background-color: #c66060;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 3px;
  max-height: 150px;
  min-width: 250px;
`;

export const Avatar = styled.div``;

export const ScrollRef = styled.div`
  /* display: flex;
  min-height: 10px; */
`;
