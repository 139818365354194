import styled from 'styled-components';
import { devices } from '../../../../constants/device';

export const ProfileWrapper = styled.div`
  height: calc(100vh - 135px);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SubtitleBar = styled.div`
  height: 62px;
  margin-left: 22px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${devices.xs} {
    margin-left: 0px;
    margin-right: 0px;
    height: 0;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const SubtitleText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const SubtitleItems = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
  width: 210px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  width: 100%;

  @media ${devices.xs} {
    margin-left: 0px;
    flex-direction: column;
  }
`;

export const TabWrapper = styled.div`
  margin-left: 15px;
`;
export const ProfileInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
  min-width: 330px;
  margin-left: 12px;
  border-radius: 10px;
  border: 0.5px solid var(--line, #d7d7d7);
  background: #f9f9fc;
  height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;

  &.doctorscroll {
    height: calc(100vh - 160px);
  }

  @media ${devices.xs} {
    width: 90%;
    background: #fff;
    border: none;
    margin-left: 20px;
    height: auto;

    &.doctorscroll {
      height: auto;
      margin-top: 10px;
    }

    &.hide {
      display: none;
    }
  }
`;

export const LogoutDivWrapper = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;

  &.doctorscroll {
    height: calc(100vh - 160px);
  }

  @media ${devices.xs} {
    height: auto;

    &.doctorscroll {
      height: auto;
      margin-top: 10px;
    }
  }
`;

export const Loader = styled.div`
  & .MuiLinearProgress-root {
    background-color: #e5f0f6;
  }

  & .MuiLinearProgress-bar {
    background-color: #0084b1;
  }
`;
