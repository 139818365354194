import styled from 'styled-components';
import { devices } from 'constants/device';

export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin-left: 16px;
  margin-top: 15px;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 180px);

  @media ${devices.xs} {
    height: calc(100vh - 650px);
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;
