import { ClaimValues } from 'types/AppointmentNewTypes';
import { ClaimErrorHistoryType, ClaimEventHistoryType } from 'types/ClaimTypes';
import { ClaimCardType } from 'types/InvoiceType';
import { api } from '../api';
import { PlaceOfServiceType } from 'pages/Billing/components/Claims/components/ClaimForm/components/ServicesTable/FieldLookup';
import { ReportsType, UserProfile } from 'types/UserProfileTypes';
import { ReportTypeEnum } from 'pages/Billing/components/InvoicePostPaymentDetail/components/InvInformPostpaymentTable/ReportsModal/ReportsModal';
import { File } from 'types/FileType';
import { CptType, CptWithGroupsType } from 'types/ServicesType';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

type Request = {
  page?: number;
  q?: string;
  id: string;
};

export const claimsApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllClaims: builder.mutation<Response<any>, any>({
      query: ({ ...params }) => ({
        url: `/claim`,
        params: { ...params },
        method: 'GET',
      }),
    }),
    getAllClaimsQuery: builder.query<Response<ClaimCardType>, any>({
      query: ({ ...arg }) => ({
        url: `/claim`,
        params: { ...arg },
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          currentCache.count = newItems.count;
          currentCache.page = newItems.page;
          currentCache.lastPage = newItems.lastPage;
          return;
        }
        currentCache.count = newItems.count;
        currentCache.lastPage = newItems.lastPage;
        currentCache.page = newItems.page;
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg, endpointState }) {
        return currentArg !== previousArg;
      },
      providesTags: ['Claims'],
    }),
    getAllClaimsForExistingSearch: builder.query<Response<ClaimCardType>, any>({
      query: ({ ...arg }) => ({
        url: `/claim`,
        params: { ...arg },
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          currentCache.count = newItems.count;
          currentCache.page = newItems.page;
          currentCache.lastPage = newItems.lastPage;
          return;
        }
        currentCache.count = newItems.count;
        currentCache.lastPage = newItems.lastPage;
        currentCache.page = newItems.page;
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg, endpointState }) {
        return currentArg !== previousArg;
      },
      providesTags: ['Claims'],
    }),
    addAppointmentToClaim: builder.mutation<Response<any>, any>({
      query: ({ id, ...body }) => ({
        url: `/claim/${id}/add-appointment`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: ['Appointments', 'Claims'],
    }),
    claimManualCreate: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/claim/create-manual`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: ['Appointments', 'Claims'],
    }),
    createClaim: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/claim/create-manual`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: ['Invoice'], // invalidatesTags: ["Appointments", "Claims"],
    }),
    deleteClaim: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/claim/${id}`,
        body: { ...body },
        method: 'DELETE',
      }),
      invalidatesTags: ['Invoice'], // invalidatesTags: ["Appointments", "Claims"],
    }),
    fullyDeleteClaim: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/claim/delete/${id}`,
        body: { ...body },
        method: 'DELETE',
      }),
      invalidatesTags: ['Invoice'], // invalidatesTags: ["Appointments", "Claims"],
    }),
    createClaimFromPatient: builder.mutation<ClaimValues, any>({
      query: ({ ...body }) => ({
        url: `/claim`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: ['Claims', 'Invoice'],
    }),
    createClaimBlank: builder.mutation<ClaimValues, any>({
      query: ({ ...body }) => ({
        url: `/claim/create-blank`,
        body: { ...body },
        method: 'POST',
      }),
      // invalidatesTags: ["Claims"],
    }),
    createClaimCopy: builder.mutation<ClaimValues, any>({
      query: ({ ...body }) => ({
        url: `/claim/create-copy`,
        body: { ...body },
        method: 'POST',
      }),
      // invalidatesTags: ["Claims"],
    }),
    getClaimById: builder.mutation<ClaimValues, any>({
      query: ({ id, ...params }) => ({
        url: `/claim/${id}`,
        params: { ...params },
        method: 'GET',
      }),
      // invalidatesTags: ["Claims"],
    }),
    getClaimStatusById: builder.query<ClaimValues, any>({
      query: ({ id, ...params }) => ({
        url: `/claim/${id}`,
        params: { ...params },
        method: 'GET',
      }),
    }),
    // checkClaimStatus: builder.mutation<ClaimValues, any>({
    //   query: ({ id, ...params }) => ({
    //     url: `/claim/${id}/status`,
    //     params: { ...params },
    //     method: 'GET',
    //   }),
    //   invalidatesTags: ['Claims'],
    // }),
    getClaimStatus: builder.query<ClaimValues, any>({
      query: ({ id, ...params }) => ({
        url: `/claim/${id}/status`,
        params: { ...params },
        method: 'GET',
      }),
      // invalidatesTags: ['Claims'],
    }),
    updateClaimPatch: builder.mutation<ClaimValues, any>({
      query: ({ ...body }) => ({
        url: `/claim/update`,
        body: { ...body },
        method: 'PATCH',
      }),
      invalidatesTags: ['Claims', 'Invoice'],
    }),
    validateClaim: builder.mutation<ClaimValues, any>({
      query: ({ id, ...body }) => ({
        url: `/claim/${id}/validate`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: ['Claims', 'Invoice'],
    }),
    releaseClaim: builder.mutation<ClaimValues, any>({
      query: ({ id, ...body }) => ({
        url: `/claim/${id}/submit`,
        body: { ...body },
        method: 'POST',
      }),
      invalidatesTags: ['Claims', 'Invoice'],
    }),
    getClaimErrorsHistory: builder.query<Response<ClaimErrorHistoryType>, any>({
      query: ({ ...arg }) => ({
        url: `/claim-errors-history`,
        params: { ...arg },
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          currentCache.count = newItems.count;
          currentCache.page = newItems.page;
          currentCache.lastPage = newItems.lastPage;
          return;
        }
        currentCache.count = newItems.count;
        currentCache.lastPage = newItems.lastPage;
        currentCache.page = newItems.page;
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg, endpointState }) {
        return currentArg !== previousArg;
      },
      providesTags: ['Claims'],
    }),
    getEventsHistory: builder.query<Response<ClaimEventHistoryType>, any>({
      query: ({ ...arg }) => ({
        url: `/claim-event`,
        params: { ...arg },
        cache: 'no-cache',
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          return;
        }
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ['Claims'],
    }),
    getClaimErrorsMessagesHistory: builder.query<Response<ClaimErrorHistoryType>, any>({
      query: ({ ...arg }) => ({
        url: `/claim-errors-history`,
        params: { ...arg },
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          currentCache.count = newItems.count;
          currentCache.page = newItems.page;
          currentCache.lastPage = newItems.lastPage;
          return;
        }
        currentCache.count = newItems.count;
        currentCache.lastPage = newItems.lastPage;
        currentCache.page = newItems.page;
        currentCache.rows.push(...newItems.rows);
      },
      forceRefetch({ currentArg, previousArg, endpointState }) {
        return currentArg !== previousArg;
      },
      providesTags: ['Claims'],
    }),
    getPlaceOfService: builder.query<PlaceOfServiceType[], any>({
      query: ({ ...arg }) => ({
        url: `/changehealthcare/pos`,
        params: { ...arg },
      }),
    }),
    getCpt: builder.query<CptType[], any>({
      query: ({ ...arg }) => ({
        url: `/changehealthcare/cpt`,
        params: { ...arg },
      }),
    }),
    getCptWithGroups: builder.query<CptWithGroupsType[], any>({
      query: ({ ...arg }) => ({
        url: `/services/cpts`,
        params: { ...arg },
      }),
    }),
    getDiagnosisCode: builder.query<
      { row: string[]; count: number },
      { type: string; terms: string; count: number; offset: number }
    >({
      query: ({ ...arg }) => ({
        url: `/changehealthcare`,
        params: { ...arg },
        cache: 'no-cache',
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.offset === 0) {
          currentCache.row = [...newItems.row];
          return;
        }
        currentCache.row.push(...newItems.row);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    patientGetOne: builder.query<UserProfile, any>({
      query: ({ id, ...arg }) => {
        return {
          url: `/user/${id}`,
          params: { ...arg },
        };
      },
    }),
    // getReportsList: builder.query<{ meta: any; reports: string[] }, any>({
    //   query: ({ ...arg }) => ({
    //     url: `/medicalnetwork/claim`,
    //   }),
    //   providesTags: ['Reports'],
    // }),
    getReportsList: builder.query<ReportsType, any>({
      query: ({ ...arg }) => ({
        url: `/claim-reports`,
      }),
      providesTags: ['Reports'],
    }),
    generatePdfReport: builder.mutation<any, { type: ReportTypeEnum; reportId: string }>({
      query: ({ type, reportId }) => ({
        url: `/claim-reports/${type}`,
        params: { reportId },
        method: 'GET',
      }),
      invalidatesTags: ['Invoice', 'Reports'],
    }),
    // removeReport: builder.mutation({
    //   query: ({ reportId }) => ({
    //     url: `/medicalnetwork/claim`,
    //     params: { reportId },
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['Reports'],
    // }),
    removeReport: builder.mutation({
      query: ({ reportId }) => ({
        url: `/claim-reports`,
        params: { reportId },
        method: 'DELETE',
      }),
      invalidatesTags: ['Reports'],
    }),
    attachmentsGet: builder.query<File[], any>({
      query: ({ claimId, ...arg }) => {
        return {
          url: `/claim/files/${claimId}`,
          params: { ...arg },
        };
      },
      providesTags: ['ClaimAttachments'],
    }),
    uploadAttachments: builder.mutation({
      query: ({ files, claimId, ...params }) => {
        const newData = new FormData();
        files.forEach((item: any) => newData.append('files', item));

        return {
          url: `/claim/upload-files`,
          method: 'POST',
          body: newData,
          params: { id: claimId },
          formData: true,
        };
      },
      invalidatesTags: ['ClaimAttachments'],
    }),
  }),
});

export const {
  useGetAllClaimsMutation,
  useAddAppointmentToClaimMutation,
  useClaimManualCreateMutation,
  useCreateClaimMutation,
  useGetAllClaimsQueryQuery,
  useLazyGetAllClaimsQueryQuery,
  useLazyGetAllClaimsForExistingSearchQuery,
  useDeleteClaimMutation,
  useFullyDeleteClaimMutation,
  useCreateClaimFromPatientMutation,
  useCreateClaimBlankMutation,
  useCreateClaimCopyMutation,
  useGetClaimByIdMutation,
  useUpdateClaimPatchMutation,
  useValidateClaimMutation,
  useReleaseClaimMutation,
  useLazyGetClaimErrorsHistoryQuery,
  useLazyGetClaimErrorsMessagesHistoryQuery,
  useGetEventsHistoryQuery,
  useLazyGetPlaceOfServiceQuery,
  useGetDiagnosisCodeQuery,
  useLazyPatientGetOneQuery,
  useLazyGetCptQuery,
  useLazyGetCptWithGroupsQuery,
  useGetReportsListQuery,
  useGeneratePdfReportMutation,
  useRemoveReportMutation,
  useLazyGetClaimStatusByIdQuery,
  useLazyAttachmentsGetQuery,
  useUploadAttachmentsMutation,
  useLazyGetClaimStatusQuery,
} = claimsApi;
