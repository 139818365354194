import dayjs from 'dayjs';
import { ClaimStatuses } from '../../../../../../../../types/AppointmentNewTypes';
import * as S from '../styles';

type Props = {
  row: ClaimStatuses;
  header: string;
};

const DialogStatusesListData = ({ row, header }: Props) => {
  let content;

  switch (header) {
    case 'Description': {
      content = row.desc ?? '-';
      break;
    }
    case 'CategoryCode': {
      content = `${row.categoryCode ?? '-'}`;
      break;
    }
    case 'Date': {
      content = row.date ? dayjs(row.date).format('MM/DD/YYYY') : '-';
      break;
    }
    default:
      content = '-';
  }

  return <S.Div>{content}</S.Div>;
};

export default DialogStatusesListData;
