import styled from 'styled-components';
import { devices } from 'constants/device';

export const Content = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px;
  gap: 6px;
  @media ${devices.xs} {
    width: auto;
    height: auto;
    padding: 10px;
  }
`;

export const Loader = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  width: 100%;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 29px;
  }
`;

export const TableBody = styled.div`
  min-width: 600px;
`;

export const Text = styled.div<{ disabled?: boolean }>`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: auto;
  min-width: 70px;
  padding: 1px 5px 1px 0;
`;
export const PleasedText = styled.div`
  color: var(--text, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: auto;
  min-width: 70px;
  text-align: center;
  width: 100%;
  padding: 1px 5px 1px 0;
`;
export const ButtonsWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 30px;
`;

export const ButtonShow = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  svg {
    opacity: 0.8;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;
