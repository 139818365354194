import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  AlertItem,
  CheckBox,
  DatePicker,
  Dialog,
  Input,
  SelectInput,
  UserStatusPin,
} from 'components';
import * as S from './styles';
import DialogAppointmentVerification from './components/Dialog_AppointmentVerification';
import { IconButton, InputAdornment, MenuItem } from '@mui/material';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { SelectChangeEvent } from '@mui/material/Select';
import { paymentsMethods } from 'constants/constants';
import dayjs, { Dayjs } from 'dayjs';
import { useAllClinicsGetQuery } from 'store/api/clinics/clinicApi';
import { useAppointmentCreateMutation } from 'store/api/appointment/appointmentApi';
import { useDispatch } from 'react-redux';
import { showDrawer } from 'store/slices/drawerSlice';
import { useAppSelector } from 'hooks/useAppSelector';
import { setMessage } from 'store/slices/message';
import { DrawerMode } from 'types/DrawerMode';
import {
  useGetClinicWeekendsMutation,
  useGetClinicWorkingTimeMutation,
} from 'store/api/clinic-schedule/clinicScheduleApi';
import { DateObject } from 'react-multi-date-picker';
import { AppointmentsNew } from 'types/AppointmentNewTypes';
import FooterBtns from './components/FooterBtns';
import AuthWithoutRegPatientInformation from './components/AuthWithoutRegPatientInformation';
import VisitReasonCard from 'components/organismus/AppointmentDrawer/components/VisitReasonCard';
import { AppointmentFormValues } from 'components/organismus/AppointmentDrawer/AppointmentDrawer';
import { AppointmentWithoutReg } from './configAuthDrawer';
import { useLazyGetServicesByClinicWithoutStoreQuery } from 'store/api/services/servicesApi';
import { CloseBtnSVG } from 'assets/icons';

type Props = {
  selectedAppointment?: AppointmentsNew;
};

const AuthDrawerContent: React.FC<Props> = ({ selectedAppointment }) => {
  const userInfo = useAppSelector(state => state.auth);
  const me = useAppSelector(state => state.auth);
  const dispatch = useDispatch();
  const [getServiceList, servicesList] = useLazyGetServicesByClinicWithoutStoreQuery();

  const formik = useFormik<AppointmentFormValues>({
    initialValues: {
      firstName: '',
      userId: '',
      mainCoveredService: '',
      mainCoveredServiceType: '',
      isReevaluation: false,
      lastName: '',
      isPossibleToUpdate: false,
      phone: '',
      dateOfBirth: '',
      // dateOfService: '',
      globalNote: '',
      email: '',
      patientNote: '',
      visits: [],
      status: '',
      authSigs: 0,
      clinicId: '',
      typeServiceIdes: [],
      date: '',
      repeat: 0,
      daysBetween: 0,
      note: '',
      paymentMethod: '',
      cashSum: '',
      coupon: '',
      cashNote: '',
      discountText: '',
    },
    validateOnChange: false,
    validationSchema: AppointmentWithoutReg,
    onSubmit: async () => {
      const data = {
        userData: {
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          phone: formik.values.phone,
          email: formik.values.email,
        },
        paymentMethod: formik.values.paymentMethod,
        clinicId: formik.values.clinicId,
        date: dayjs(formik.values.date).format('YYYY-MM-DD'),
        visits: formik.values.visits?.map(visit => ({
          typeServiceId: visit.typeServiceId,
          startTime: visit.startTime,
          visitLengthMinutes: visit.visitLengthMinutes,
          specialistId: visit.specialistId || undefined,
        })),
        patientNote: formik.values.patientNote,
      };

      await appointment(data)
        .unwrap()
        .then(res => {
          handleChangeDialog();
          dispatch(
            setMessage({
              message: 'Appointment was successfully created',
              type: 'success',
            }),
          );
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
          if (userInfo && userInfo?.clinics?.length === 0) {
            window.location.reload();
          }
          resetForm();
        })
        .catch(error => {
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    },
  });

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
  } = formik;

  useEffect(() => {
    if (selectedAppointment) {
      formik.setValues({
        ...formik.values,
        firstName: selectedAppointment.patient?.profile?.firstName || '',
        lastName: selectedAppointment.patient?.profile?.lastName || '',
        phone: selectedAppointment.patient?.phone || '',
        email: selectedAppointment.patient?.email || '',
        patientNote: selectedAppointment.patientNote || '',
        clinicId: selectedAppointment.clinicId || '',
        visits:
          selectedAppointment.visits?.map(visit => {
            return {
              id: visit.id,
              typeServiceId: visit.typeServiceId,
              startTime: visit.startTime.slice(0, 5),
              visitLengthMinutes: visit.visitLengthMinutes,
              specialistId: visit.specialistId || '',
            };
          }) || [],
        typeServiceIdes: selectedAppointment.visits?.map(visit => visit.typeServiceId),
        date: dayjs(selectedAppointment.date) || '',
        authSigs: selectedAppointment.authSigs || 0,
        globalNote: selectedAppointment.globalNote || '',
        note: selectedAppointment.note || '',
        isReevaluation: selectedAppointment.isReevaluation || false,
        paymentMethod: selectedAppointment.paymentMethod || '',
        cashSum: selectedAppointment.cashSum ?? '',
        coupon: selectedAppointment.coupon?.value ?? '',
        userId: selectedAppointment.patient?.id ?? '',
        mainCoveredService: selectedAppointment.mainVisitReason ?? '',
        mainCoveredServiceType: selectedAppointment.mainVisitReasonType ?? '',
        status: selectedAppointment.status ?? '',
        cashNote: selectedAppointment.cashNote ?? '',
        dateOfBirth: selectedAppointment.patient?.profile?.dateOfBirth || '',
      });
    }
  }, [selectedAppointment]);

  const clinic = useAllClinicsGetQuery({
    take: 50,
    status: 'active',
  });
  const [appointment] = useAppointmentCreateMutation();

  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeDialog = () => {
    setOpenDialog(openDialog => !openDialog);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  const [currentMonth, setCurrentMonth] = useState<DateObject>(new DateObject());

  const [getWeekends, getWeekendsStatus] = useGetClinicWeekendsMutation({});
  const [getTime, getTimeStatus] = useGetClinicWorkingTimeMutation({});

  const [arrWeekends, setArrWeekends] = useState<string[] | null>(null);
  const [arrTime, setArrTime] = useState<string[] | null>(null);

  useEffect(() => {
    setCurrentMonth(new DateObject());
    if (values.clinicId && !selectedAppointment) {
      setValues({
        ...values,
        date: '',
      });
    }
    if (values.clinicId) {
      getServiceList({
        clinicId: values.clinicId,
        take: 100,
        asc: 'DESC',
        page: 1,
      });
    }
  }, [values.clinicId]);

  useEffect(() => {
    if (currentMonth && values.clinicId) {
      getWeekends({
        clinicId: values.clinicId,
        date: currentMonth.format('YYYY-MM-01'),
      })
        .unwrap()
        .then((res: Array<string>) => setArrWeekends(res));
    }
  }, [currentMonth, values.clinicId]);

  useEffect(() => {
    if (!dayjs(values.date as Dayjs).isValid()) {
      setCurrentMonth(new DateObject());
    } else {
      getTime({
        clinicId: values.clinicId,
        date: dayjs(values.date).format('YYYY-MM-DD'),
      })
        .unwrap()
        .then(res => {
          setArrTime(res.time);
        });
    }
  }, [values.date]);

  useEffect(() => {
    if (userInfo && !selectedAppointment) {
      formik.setValues({
        ...formik.values,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        phone: userInfo.phone,
        email: userInfo.email,
      });
    }
  }, [userInfo]);

  return (
    <>
      <Dialog open={openDialog} onClose={handleChangeDialog}>
        <DialogAppointmentVerification onClose={handleChangeDialog} />
      </Dialog>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <FormikProvider value={formik}>
          <S.ContentWrapper>
            <S.Content>
              <S.ArticleWrap>
                {selectedAppointment ? (
                  <>
                    <S.Article>
                      Appointment # {selectedAppointment.id.slice(0, 8)}
                    </S.Article>
                    <UserStatusPin type={selectedAppointment?.status} />
                  </>
                ) : (
                  <>
                    <S.Article>Add an Appointment</S.Article>
                  </>
                )}
              </S.ArticleWrap>
              <S.AlertWrapper>
                <AlertItem
                  text={
                    selectedAppointment
                      ? 'In case you want to change some appointment details, please cancel current request and create new one'
                      : 'Before you save the form, please make sure that your insurance form is filled'
                  }
                  type="info"
                />
              </S.AlertWrapper>
              <S.ScrollContainer>
                <AuthWithoutRegPatientInformation userInfo={userInfo} formik={formik} />
                <S.PatientInformation>
                  <S.FirstTimeVisit>
                    <S.FirstVisitContent>
                      <S.LabelText>
                        Payment method <S.RequiredSpan>*</S.RequiredSpan>
                      </S.LabelText>
                      <S.SelectInputPay>
                        <SelectInput
                          label=""
                          disabled={!!selectedAppointment}
                          id="paymentMethod"
                          name="paymentMethod"
                          error={!!errors.paymentMethod}
                          helperText={errors.paymentMethod}
                          value={values.paymentMethod}
                          onChange={(e: SelectChangeEvent<unknown>) => {
                            setFieldError('paymentMethod', '');
                            handleChange(e);
                          }}
                          endAdornment={
                            !!formik.values.paymentMethod && (
                              <InputAdornment sx={{ marginRight: '12px' }} position="end">
                                <IconButton
                                  onClick={() => {
                                    formik.setFieldValue('paymentMethod', '');
                                  }}
                                >
                                  <CloseBtnSVG />
                                </IconButton>
                              </InputAdornment>
                            )
                          }
                        >
                          {paymentsMethods.map(pay => (
                            <MenuItem key={pay.name} value={pay.value}>
                              <S.MenuItemContent>{pay.name}</S.MenuItemContent>
                            </MenuItem>
                          ))}
                        </SelectInput>
                      </S.SelectInputPay>
                    </S.FirstVisitContent>
                  </S.FirstTimeVisit>
                </S.PatientInformation>
                <S.PatientInformation>
                  <S.SubArticle>Appointment information</S.SubArticle>
                  <S.PatientInputs>
                    <SelectInput
                      label="Clinic name"
                      isRequired
                      id="clinicId"
                      disabled={!!selectedAppointment}
                      name="clinicId"
                      value={values.clinicId}
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        setFieldError('clinicId', '');
                        setFieldValue('typeServiceIdes', []);
                        handleChange(e);
                      }}
                      error={!!errors.clinicId}
                      helperText={errors.clinicId}
                    >
                      {clinic.currentData?.rows.map(item => {
                        return (
                          <MenuItem value={item.id} key={item.id}>
                            <S.MenuItemContent>{item.name}</S.MenuItemContent>
                          </MenuItem>
                        );
                      })}
                    </SelectInput>
                    {!values.clinicId && (
                      <S.HelperText>
                        To continue filling out the fields, select a clinic
                      </S.HelperText>
                    )}
                    {values.clinicId && (
                      <S.InputRow>
                        <S.SelectInputWrapper>
                          <DatePicker
                            shouldDisableDate={date => {
                              if (
                                arrWeekends &&
                                arrWeekends?.includes(dayjs(date).format('YYYY-MM-DD'))
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            }}
                            onOpen={() => {
                              !dayjs(values.date as Dayjs).isValid()
                                ? setCurrentMonth(new DateObject())
                                : setCurrentMonth(
                                    new DateObject(
                                      dayjs(values.date).format('YYYY-MM-DD'),
                                    ),
                                  );
                            }}
                            onMonthChange={date => setCurrentMonth(date)}
                            label="Preferred Date"
                            isRequired
                            disabled={
                              selectedAppointment ||
                              getWeekendsStatus.isLoading ||
                              !values.clinicId
                                ? true
                                : false
                            }
                            disablePast
                            value={values.date}
                            onChange={value => {
                              setFieldError('date', '');
                              setFieldValue('date', value);
                            }}
                            id="date"
                            name="date"
                            error={
                              !!errors.date ||
                              !!(values.date && !dayjs(values.date as Dayjs).isValid())
                            }
                            helperText={
                              errors.date ||
                              (values.date !== '' &&
                                !dayjs(values.date as Dayjs).isValid())
                                ? 'Not valid format'
                                : ''
                            }
                          />
                        </S.SelectInputWrapper>{' '}
                        {!formik.values.date ? (
                          <S.HelperText>
                            To continue filling out the fields, select a preffered date
                          </S.HelperText>
                        ) : (
                          <S.SelectInputWrapper>
                            <SelectInput
                              label="Visit reason"
                              isRequired
                              multiple
                              id={`typeServiceIdes`}
                              name={`typeServiceIdes`}
                              error={!!formik.errors.typeServiceIdes}
                              helperText={formik.errors.typeServiceIdes}
                              renderValue={(selected: any) => (
                                <S.SelectInputOutput>
                                  <S.SelectInputOutputText>
                                    {servicesList.currentData?.rows
                                      ?.filter(i => selected.includes(i.id))
                                      ?.map(o => o.serviceName)
                                      ?.join('+')}
                                  </S.SelectInputOutputText>
                                </S.SelectInputOutput>
                              )}
                              value={formik.values.typeServiceIdes}
                              onChange={(e: SelectChangeEvent<unknown>) => {
                                formik.setFieldError(`typeServiceIdes`, '');
                                formik.setFieldValue(
                                  `visits`,
                                  (e.target.value as string[]).map(reason => {
                                    const existingVisit = formik.values.visits.find(
                                      visit => visit.typeServiceId === reason,
                                    );
                                    const defaultServiceTime =
                                      servicesList.currentData?.rows?.find(
                                        i => i.id === reason,
                                      )?.defaultServiceTime;

                                    return (
                                      existingVisit || {
                                        typeServiceId: reason,
                                        startTime: '',
                                        visitLengthMinutes: defaultServiceTime
                                          ? defaultServiceTime
                                          : 0,
                                        specialistId: '',
                                      }
                                    );
                                  }),
                                );
                                formik.handleChange(e);
                              }}
                              disabled={!!selectedAppointment}
                            >
                              {servicesList.currentData?.rows?.map(reason =>
                                reason?.isHold ? null : (
                                  <MenuItem
                                    key={reason.id}
                                    value={reason.id}
                                    disabled={servicesList.isFetching}
                                  >
                                    <S.MenuItemContent>
                                      <CheckBox
                                        checked={formik.values.typeServiceIdes.includes(
                                          reason?.id,
                                        )}
                                      />
                                      {reason.serviceName}
                                    </S.MenuItemContent>
                                  </MenuItem>
                                ),
                              )}
                            </SelectInput>
                          </S.SelectInputWrapper>
                        )}
                      </S.InputRow>
                    )}

                    {!formik.values.typeServiceIdes?.length ? (
                      <S.HelperText>
                        {formik.values.date
                          ? 'To continue filling out the fields, select a visit reason'
                          : ''}
                      </S.HelperText>
                    ) : (
                      <>
                        <S.VisitReasons>
                          <FieldArray
                            name="visits"
                            render={arrayHelpers => (
                              <>
                                {formik.values.visits.map((item, index) => {
                                  return (
                                    <VisitReasonCard
                                      key={index}
                                      visit={item}
                                      formik={formik}
                                      selectedAppointment={selectedAppointment}
                                      arrTime={arrTime}
                                      index={index}
                                      selectedClinicId={formik.values.clinicId}
                                      serviceName={
                                        servicesList.currentData?.rows?.find(
                                          i => i.id === item?.typeServiceId,
                                        )?.serviceName ?? '-'
                                      }
                                      defaultServiceTime={
                                        servicesList.currentData?.rows?.find(
                                          i => i.id === item?.typeServiceId,
                                        )?.defaultServiceTime
                                      }
                                    />
                                  );
                                })}
                              </>
                            )}
                          />
                        </S.VisitReasons>
                      </>
                    )}

                    <Input
                      multiline={true}
                      rows={5}
                      label="Note"
                      id="patientNote"
                      disabled={!!(selectedAppointment || !values.clinicId)}
                      name="patientNote"
                      error={!!errors.patientNote}
                      value={values.patientNote}
                      onChange={handleChangeInput}
                    />
                  </S.PatientInputs>
                </S.PatientInformation>
              </S.ScrollContainer>
            </S.Content>
            <FooterBtns selectedAppointment={selectedAppointment} />
          </S.ContentWrapper>
        </FormikProvider>
      </form>
    </>
  );
};

export default AuthDrawerContent;
