import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Drawer,
  HorizontalHeader,
  LinearLoader,
  MenuSectionItem,
  VerticalMenu,
} from '../../components';
import * as S from './styles';
import Patients from '../Receptionist/components/Patients';
import {
  AccountingTableSVG,
  AvatarSVG,
  CalendarSVG,
  ChatsSVG,
  ClaimMenuSVG,
  InvoicesSVG,
  TasksSVG,
  TemplatesSVG,
  TicketSVG,
} from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { useClinicsGetQuery } from 'store/api/clinics/clinicApi';
import { useLastSelectedClinicMutation } from 'store/api/user/profileApi';
import { setSelectedClinic } from 'store/slices/selectedClinic';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { getChats } from 'hooks/useChat';
import Profile from '../Receptionist/components/Profile';
import ChooseDrawerContent from '../../services/chooseDrawerContent';
import AccountingTable from './components/AccountingTable';
import Invoices from './components/Invoices';
import InvoiceDetail from './components/InvoiceDetail';
import InvoicePrepaymentDetail from './components/InvoicePrepaymentDetail';
import PatientProfile from '../Receptionist/components/PatientProfile';
import Tickets from '../Receptionist/components/Tickets';
import Claims from './components/Claims';
import InvoicePostPaymentDetail from './components/InvoicePostPaymentDetail/InvoicePostPaymentDetail';
import AppointmentsView from '../../components/organismus/AppointmentsView';
import Templates from '../SuperAdmin/components/Templates';
import InitialVisitFormPage from '../SuperAdmin/components/Templates/components/pages/InitialVisitFormPage';
import PagePTInsurance from '../SuperAdmin/components/Templates/components/pages/PagePhysicalTherapyInsurance';
import PageClientRegistration from '../SuperAdmin/components/Templates/components/pages/ClientRegistrationPage';
import PageACAInsurance from '../SuperAdmin/components/Templates/components/pages/PageACAInsurance';
import PagePTInitialConsultation from '../SuperAdmin/components/Templates/components/pages/PagePTInitialConsultation';
import ClaimForm from './components/Claims/components/ClaimForm';
import Chats from 'pages/Receptionist/components/Chats';

const Billing = () => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(state => state.auth);
  const [clinic, setClinics] = useState('');
  const navigate = useNavigate();
  const clinics = useClinicsGetQuery({});
  const [changeSelectedClinic, status] = useLastSelectedClinicMutation();
  const location = useLocation().pathname;
  const connected = useAppSelector(({ chats }) => chats.connected);
  const drawerState = useAppSelector(state => state.drawer);

  useEffect(() => {
    if (connected) {
      getChats();
    }
  }, [connected]);

  useEffect(() => {
    if (!clinic && !userInfo.selectedClinicId) {
      clinics.currentData?.rows[0]?.id && setClinics(clinics.currentData?.rows[0]?.id);
      clinics.currentData?.rows[0]?.id &&
        dispatch(setSelectedClinic({ id: clinics.currentData?.rows[0]?.id }));
      changeSelectedClinic({ clinicId: clinics.currentData?.rows[0]?.id });
    }

    if (!clinic) {
      userInfo?.selectedClinicId && setClinics(userInfo.selectedClinicId);
      userInfo?.selectedClinicId &&
        dispatch(setSelectedClinic({ id: userInfo.selectedClinicId }));
    }
  }, [clinics]);

  useEffect(() => {
    dispatch(setSelectedClinic({ id: clinic }));
  }, [clinic]);

  const routeList = [
    {
      article: 'Accounting table',
      route: '/accountingtable',
      icon: <AccountingTableSVG />,
    },
    { article: 'Appointments', route: '/appointments', icon: <CalendarSVG /> },
    { article: 'Patients', route: '/patients', icon: <AvatarSVG /> },
    { article: 'Chats', route: '/chats', icon: <ChatsSVG /> },
    { article: 'Tickets', route: '/tickets', icon: <TasksSVG /> },
    { article: 'Claims', route: '/claims', icon: <ClaimMenuSVG /> },
    { article: 'Invoices', route: '/invoices', icon: <InvoicesSVG /> },
    { article: 'Templates', route: '/templates', icon: <TemplatesSVG /> },
  ];

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const { isHover } = useAppSelector(state => state.isHover);

  return (
    <>
      <Drawer
        open={drawerState.show}
        onClose={() =>
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }))
        }
      >
        <ChooseDrawerContent />
      </Drawer>

      <S.Wrapper>
        <HorizontalHeader isNotify={true} />
        {selectedClinic.id ? (
          <S.Content $isHover={isHover}>
            <Routes>
              {/* Routes forms */}
              <Route
                path="templates/initial-visit-form"
                element={<InitialVisitFormPage />}
              />
              <Route
                path="templates/physical-therapy-insurance"
                element={<PagePTInsurance />}
              />
              <Route
                path="templates/client-registration-form"
                element={<PageClientRegistration />}
              />
              <Route
                path="/templates/acupuncture-insurance"
                element={<PageACAInsurance />}
              />
              <Route
                path="/templates/pt-initial-evaluation"
                element={<PagePTInitialConsultation />}
              />
              {/* Routes forms */}
              <Route path="patients" element={<Patients />} />
              <Route path="/appointments" element={<AppointmentsView />} />
              <Route path="profile" element={<Profile />} />
              <Route path="invoices/sheet/:invoiceId" element={<InvoiceDetail />} />
              <Route path="tickets" element={<Tickets />} />
              <Route path="patients/:patientId" element={<PatientProfile />} />
              <Route
                path="invoices/pre-payment/:id"
                element={<InvoicePrepaymentDetail />}
              />
              <Route
                path="invoices/post-payment/:id"
                element={<InvoicePostPaymentDetail />}
              />
              <Route path="chats" element={<Chats />} />
              <Route path="claims" element={<Claims />} />
              <Route path="claim-form/:claimId?" element={<ClaimForm />} />
              <Route path="templates" element={<Templates />} />
              <Route path="invoices/*" element={<Invoices />} />
              <Route path="accountingtable" element={<AccountingTable />} />
              <Route path="*" element={<Navigate to="/appointments" />} />
            </Routes>
          </S.Content>
        ) : (
          <LinearLoader />
        )}
        <VerticalMenu>
          <S.Items>
            {routeList.map(route => {
              return (
                <MenuSectionItem
                  key={route.article}
                  text={route.article}
                  onClick={() => navigate(`${route.route}`)}
                  $active={location.includes(`${route.route}`) && true}
                >
                  {route.icon}
                </MenuSectionItem>
              );
            })}
          </S.Items>
          <S.Buttons>
            <S.ButtonWrapper $isHover={isHover}>
              <Button
                text={isHover && window.innerWidth > 450 ? 'Make a ticket' : ''}
                onClick={() =>
                  dispatch(
                    showDrawer({
                      show: true,
                      mode: DrawerMode.TICKET_CREATE,
                    }),
                  )
                }
              >
                <TicketSVG />
              </Button>
            </S.ButtonWrapper>
          </S.Buttons>
        </VerticalMenu>
      </S.Wrapper>
    </>
  );
};

export default Billing;
