import React, { useEffect, useState } from 'react';
import { useLazyGetCptQuery } from 'store/api/claims/claimsApi';
import * as S from './styles';
import { CustomTable, RadioButton } from 'components';
import Button from 'components/atoms/Button';
import { TableCell, TableRow } from '@mui/material';

const headers = [
  { name: '', key: 'checkbox' },
  { name: 'Code', key: 'code' },
  { name: 'Description', key: 'description' },
  /*{ name: 'Effective', key: 'effective' },
  { name: 'Terminated', key: 'terminated' },
  { name: 'Gender', key: 'gender' },*/
];

type Props = {
  onApplyClick: (code: string) => void;
  currentValue?: string;
  title?: string;
  //isFullTable?: boolean;
};

export const ChooseCptCode = ({
  onApplyClick,
  currentValue,
  title = 'Choose HCPCS/CPT Code',
  //isFullTable = true,
}: Props) => {
  //const [search, setSearch] = useState('');
  const [getData, statusCpt] = useLazyGetCptQuery({});
  //const [debouncedSearch] = useDebounce(search, 500);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  useEffect(() => {
    getData({});
  }, [getData]);

  useEffect(() => {
    if (currentValue) {
      setSelectedItem(statusCpt.data?.find(i => i?.code === currentValue)?.code ?? null);
    }
  }, [statusCpt]);

  return (
    <S.Content>
      <S.Article>{title}</S.Article>
      {/*<S.SearchWrapper>
        <SearchInput
          isSearch={!!search}
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </S.SearchWrapper>*/}
      <S.Table className={'all'}>
        <CustomTable headers={headers}>
          {!!statusCpt.data?.length &&
            statusCpt.data?.map(row => (
              <TableRow
                key={row?.code}
                sx={{
                  '& .MuiTableCell-root': {
                    background: selectedItem === row?.code ? '#e7e8ec' : '',
                  },
                }}
              >
                <TableCell>
                  <RadioButton
                    onClick={() =>
                      selectedItem === row?.code
                        ? setSelectedItem(null)
                        : setSelectedItem(row?.code)
                    }
                    checked={selectedItem === row?.code}
                  />
                </TableCell>
                <TableCell>
                  <S.Text>{row?.code ?? '-'}</S.Text>
                </TableCell>
                <TableCell>
                  <S.Text>{row?.desc ?? '-'}</S.Text>
                </TableCell>
                {/*{isFullTable && (
                  <>
                    <TableCell>
                      <S.Text>{row.description}</S.Text>
                    </TableCell>
                    <TableCell>
                      <S.Text>{row.description}</S.Text>
                    </TableCell>
                    <TableCell>
                      <S.Text>{row.description}</S.Text>
                    </TableCell>
                  </>
                )}*/}
              </TableRow>
            ))}
        </CustomTable>
      </S.Table>

      <S.ButtonWrapper>
        <Button text="Apply" onClick={() => onApplyClick(selectedItem ?? '')} />
      </S.ButtonWrapper>
    </S.Content>
  );
};
