import React, { useState } from 'react';
import CircularLoader from 'components/atoms/CircuralLoader';
import { CustomTable, Dialog, SuccessDialog } from 'components';
import { TableCell, TableRow } from '@mui/material';
import { DeleteRedBtnSVG, PDFSVG } from 'assets/icons';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import { useDispatch } from 'react-redux';
import {
  useGeneratePdfReportMutation,
  useGetReportsListQuery,
  useRemoveReportMutation,
} from 'store/api/claims/claimsApi';
import { useAppSelector } from 'hooks/useAppSelector';
import { useClinicsGetQuery } from 'store/api/clinics/clinicApi';
import { setMessage } from 'store/slices/message';

import * as S from './styles';
import { PleasedText } from './styles';
import dayjs from 'dayjs';

const headers = [
  { name: 'Report Name', key: 'reportName' },
  { name: 'Date', key: 'Date' },
  { name: '', key: 'buttons' },
];

export enum ReportTypeEnum {
  TYPE_835 = '835',
  TYPE_277 = '277',
}

const checkIs835ReportType = (report?: string) => {
  return report?.slice(0, 2) === 'R5';
};

export const ReportsModal = () => {
  const dispatch = useDispatch();
  const { id: selectedClinicId } = useAppSelector(state => state.selectedClinic);
  const clinics = useClinicsGetQuery({});
  const [generateReport, generateStatus] = useGeneratePdfReportMutation();
  const [removeReport, removeStatus] = useRemoveReportMutation({});

  const [showDeleteDialog, setShowDeleteDialog] = useState<string | null>(null);
  const [showGenerateDialog, setShowGenerateDialog] = useState<string | null>(null);

  const getData = useGetReportsListQuery({});

  return (
    <S.Content>
      {(generateStatus.isLoading || removeStatus.isLoading) && (
        <S.Loader>
          <CircularLoader color="#0084B1" size={16} />
        </S.Loader>
      )}
      <S.Article>Reports</S.Article>
      <S.PleasedText>Please delete already downloaded reports</S.PleasedText>
      <S.TableBody>
        <CustomTable headers={headers} isFetching={getData.isFetching}>
          {getData?.data &&
            !!getData?.data.length &&
            getData?.data?.map((row, index) => {
              return row.fileName ? (
                <TableRow
                  key={row.fileName}
                  className={row.isSent ? 'notEdit grayRow' : 'notEdit'}
                  sx={{
                    opacity: row.isSent ? 0.6 : 1,
                  }}
                >
                  <TableCell>
                    <S.Text disabled={row.isSent}>{row.fileName}</S.Text>
                  </TableCell>
                  <TableCell>
                    <S.Text disabled={row.isSent}>
                      {row.date ? dayjs(row.date).format('YYYY-MM-DD') : '-'}
                    </S.Text>
                  </TableCell>
                  <TableCell>
                    <S.ButtonsWrapper disabled={row.isSent}>
                      <S.ButtonShow
                        onClick={() => setShowGenerateDialog(row.fileName)}
                        disabled={row.isSent}
                      >
                        <PDFSVG />
                      </S.ButtonShow>
                      {row.isSent && (
                        <S.ButtonShow
                          onClick={() => setShowDeleteDialog(row.fileName)}
                          disabled={row.isSent}
                        >
                          <DeleteRedBtnSVG />
                        </S.ButtonShow>
                      )}
                    </S.ButtonsWrapper>
                  </TableCell>
                </TableRow>
              ) : null;
            })}
        </CustomTable>
      </S.TableBody>
      <Dialog open={!!showGenerateDialog} onClose={() => setShowGenerateDialog(null)}>
        <SuccessDialog
          helperText="Are you sure, that you want to generate pdf file from this report?"
          successText="Generate"
          onClose={() => setShowGenerateDialog(null)}
          titleText="Generate pdf file?"
          onSuccess={() =>
            !!showGenerateDialog &&
            generateReport({
              type: checkIs835ReportType(showGenerateDialog)
                ? ReportTypeEnum.TYPE_835
                : ReportTypeEnum.TYPE_277,
              reportId: showGenerateDialog,
            })
              .unwrap()
              .then(() => {
                setShowGenerateDialog(null);
                dispatch(
                  setMessage({
                    message: 'Pdf file was successfully generated',
                    type: 'success',
                  }),
                );
              })
              .catch(error => {
                dispatch(setMessage({ message: error.data.message, type: 'error' }));
              })
          }
        />
      </Dialog>

      <Dialog open={!!showDeleteDialog} onClose={() => setShowDeleteDialog(null)}>
        <ConfirmDeleteDialog
          onClose={() => setShowDeleteDialog(null)}
          fullTitle={'Are you sure you want to delete this report?'}
          onSuccess={() =>
            !!showDeleteDialog &&
            removeReport({ reportId: showDeleteDialog })
              .unwrap()
              .then(res => {
                dispatch(
                  setMessage({
                    message: 'Report was successfully deleted',
                    type: 'success',
                  }),
                );
              })
              .catch(error => {
                dispatch(
                  setMessage({
                    message: error.data.message,
                    type: 'error',
                  }),
                );
              })
          }
        />
      </Dialog>
    </S.Content>
  );
};
