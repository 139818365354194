import { useEffect } from 'react';
import { io } from 'socket.io-client';
import {
  ChatResponse,
  MessageResponse,
  NewMessagesResponse,
  NotificationCount,
  PendingAppointmentsCount,
} from 'types/ChatTypes';
import { useAppDispatch } from './useAppDispatch';
import {
  setChats,
  setConnected,
  setMessages,
  setNewMessage,
  setNewNotifications,
  setNotificationCount,
  setPendingAppointmentsCount,
  setStartedAppointmentCount,
  setUpdateClaim,
  StartAppointment,
} from 'store/slices/chatSlice';
import { useAppSelector } from './useAppSelector';
import { UserStatus } from 'types/UserStatuses';

const WSURL = process.env.REACT_APP_WS_URL || '';

export const socket = io(WSURL, {
  withCredentials: true,
});

export const useChat = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(state => state.auth.status);

  useEffect(() => {
    socket.on('chats', (chats: ChatResponse) => {
      dispatch(setChats(chats));
    });

    socket.on('messages', (messages: MessageResponse) => {
      dispatch(setMessages(messages));
    });

    socket.on('messageAdded', (newMessage: NewMessagesResponse) => {
      dispatch(setNewMessage(newMessage));
    });

    socket.on(
      'getPendingAppointmentCount',
      (pendingAppointmentCount: PendingAppointmentsCount) => {
        dispatch(setPendingAppointmentsCount(pendingAppointmentCount));
      },
    );

    socket.on('connect', () => {
      dispatch(setConnected(true));
    });

    socket.on('newNotification', (newNotification: any) => {
      dispatch(setNewNotifications(newNotification));
    });

    socket.on('getNotificationsCount', (notificationsCount: NotificationCount) => {
      dispatch(setNotificationCount(notificationsCount));
    });
    socket.on(
      'getStartedAppointmentCount',
      (startedAppointmentCount: StartAppointment) => {
        dispatch(setStartedAppointmentCount(startedAppointmentCount));
      },
    );
    socket.on('claimIsUpdated', (claimIsUpdated: boolean) => {
      dispatch(setUpdateClaim(claimIsUpdated));
    });
  }, []);

  useEffect(() => {
    if (status === UserStatus.VERIFIED_FULL) {
      socket.connect();
    }
  }, [status]);
};

export const joinChat = (chatId: string) => {
  socket.emit('joinChat', { chatId });
};

export const readMessage = (messageId: string, chatId: string) => {
  socket.emit('readMessage', { messageId, chatId });
};

export const sendMessage = (text: string, chatId: string, ids?: Array<string>) => {
  socket.emit('sendMessage', { text, chatId, filesIds: ids });
};

export const getChats = (page?: number, q?: string) => {
  socket.emit('getChats', {
    page: page || 1,
    take: 100,
    asc: 'DESC',
    q: q || '',
  });
};
