import styled from 'styled-components';
import { devices } from 'constants/device';

export const Content = styled.div`
  width: auto;
  min-width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  gap: 15px;
  @media ${devices.xs} {
    width: auto;
    height: auto;
    padding: 10px;
  }
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 29px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 176px;

  @media ${devices.xs} {
    width: 280px;
  }
`;

export const SearchWrapper = styled.div`
  width: 572px;
  margin-right: 6px;

  @media ${devices.xs} {
    display: none;
  }
`;

export const Table = styled.div`
  width: 100%;
  max-width: 60vw;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;

  &.all {
    max-width: 100%;
  }
`;

export const Text = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: auto;
  min-width: 70px;
  padding: 1px 5px 1px 0;

  &.ml {
    font-style: italic;
    font-weight: 300;
    color: var(--text, #848a9b);
  }
`;

export const CodeDescContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
