import styled from 'styled-components';
import { Box } from '@mui/material';

export const CustomBox = styled(Box)`
  display: inline-block;
  padding: 2px 10px;
  margin: 0.5rem 0.5rem 0 0;
  border-radius: 5px;
  background: rgba(0, 132, 177, 0.2);

  span {
    padding: 0 0.2rem;
    color: var(--text, #0084b1);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: opacity 0.2s;
  }

  &:hover > span {
    &:last-child {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;
