import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { HorizontalHeader, MenuSectionItem, VerticalMenu } from 'components';
import * as S from './styles';
import { AvatarSVG, CalendarSVG } from 'assets/icons';
import Chats from '../Receptionist/components/Chats/Chats';
import Appointments from './components/Appointments';
import Profile from './components/Profile';
import { useAppSelector } from 'hooks/useAppSelector';

const Users = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const { isHover } = useAppSelector(state => state.isHover);

  return (
    <>
      <S.Wrapper>
        <HorizontalHeader isNotify={true} />
        <S.Content $isHover={isHover}>
          <Routes>
            <Route path="appointments" element={<Appointments />} />
            <Route path="chats" element={<Chats />} />
            <Route path="profile" element={<Profile />} />
            <Route path="*" element={<Navigate to="/appointments" />} />
          </Routes>
        </S.Content>
        <VerticalMenu>
          <MenuSectionItem
            text="Appointments"
            onClick={() => navigate('/appointments')}
            $active={location === '/appointments' && true}
          >
            <CalendarSVG />
          </MenuSectionItem>
          {/*<MenuSectionItem
            className="hide"
            text="Profile"
            onClick={() => navigate('/profile')}
            $active={location === '/profile' && true}
          >
            <AvatarSVG />
          </MenuSectionItem>*/}
        </VerticalMenu>
      </S.Wrapper>
    </>
  );
};

export default Users;
