import React from 'react';
import {
  InvoiceHistoryOperation,
  InvoiceShowHistory,
} from '../../../../../../types/InvoiceType';

import * as S from './styles';

export const getTextHistory = (item: InvoiceShowHistory) => {
  switch (item.operation) {
    case InvoiceHistoryOperation.ADD_ITEM:
      return (
        <S.RowArticle>
          <S.SpanWeight>{`Add ITEM ID : ${item.invoiceItemId} `}</S.SpanWeight>
        </S.RowArticle>
      );
    case InvoiceHistoryOperation.CREATE:
      return (
        <S.RowArticle>
          <S.SpanWeight>{`Created  `}</S.SpanWeight>
        </S.RowArticle>
      );
    case InvoiceHistoryOperation.UPDATE_ITEM:
      function getChangedElements(changeListItem: {
        to: number[];
        key: string;
        from: number[];
      }) {
        const { to, from, key } = changeListItem;

        const changes = to
          .map((value, index) => ({ to: value, from: from[index], key }))
          .filter(({ to, from }) => to !== from);

        return changes;
      }
      return (
        <S.RowArticle>
          <S.SpanWeight>Updated: </S.SpanWeight>
          claim #<S.SpanWeight>{item.claimId ? `${item.claimId} ` : ' - '}</S.SpanWeight>
          {item.changeList?.map((change, index) => {
            const changes =
              Array.isArray(change.to) && Array.isArray(change.from)
                ? change.to
                    .map((toValue, idx) => ({
                      to: toValue,
                      from: change.from[idx],
                      key: change.key,
                    }))
                    .filter(({ to, from }) => to !== from)
                : change.to !== change.from
                  ? [{ key: change.key, from: change.from, to: change.to }]
                  : [];
            return changes.length > 0
              ? changes.map(({ key, from, to }, changeIdx) => (
                  <React.Fragment key={`${index}-${changeIdx}`}>
                    <S.RowArticle>
                      <S.SpanWeight> "{key}" </S.SpanWeight>
                      from{' '}
                      <S.SpanWeight>
                        {from !== undefined ? from : '" - "'}
                      </S.SpanWeight>{' '}
                      to <S.SpanWeight>{to !== undefined ? to : '" - "'}</S.SpanWeight>
                    </S.RowArticle>
                  </React.Fragment>
                ))
              : null;
          })}{' '}
          by{' '}
          <S.SpanBy>
            {item.editor?.profile?.firstName + ' ' + item.editor?.profile?.lastName}
          </S.SpanBy>
        </S.RowArticle>
      );
    default:
      return '-';
  }
};
