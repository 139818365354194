import styled, { css } from 'styled-components';
import { devices } from 'constants/device';

export const Wrapper = styled.div`
  height: 100%;
`;

export const AlertWrapper = styled.div`
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 538px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const Content = styled.div<{ $isHover?: boolean }>`
  margin-left: 85px;
  height: calc(100vh - 63px);
  transition: 0.5s;

  &.opacity {
    opacity: 0.5;
    transition: 0.5s;
  }

  ${props =>
    props.$isHover &&
    css`
      opacity: 0.5;
      transition: 0.5s;
    `}

  @media ${devices.xs} {
    margin-left: 0px;
    height: 100%;
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100%;

  @media ${devices.xs} {
    justify-content: center;
    flex-direction: row;
    height: 80px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
`;

export const AppointmentCircle = styled.div<{ $isHover: boolean }>`
  border-radius: 50%;
  transition: 0.2s;
  background-color: #d67300;
  display: flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;

  ${props =>
    props.$isHover &&
    css`
      width: 0;
      height: 0;
      transition: 0.2s;
    `}
`;

export const ChatsCircle = styled.div<{ $isHover: boolean }>`
  border-radius: 50%;
  transition: 0.2s;
  background-color: #0084b1;
  display: flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;

  ${props =>
    props.$isHover &&
    css`
      width: 0;
      height: 0;
      transition: 0.2s;
    `}
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const Loader = styled.div`
  & .MuiLinearProgress-root {
    background-color: #e5f0f6;
    /* width: 100px !important;
    height: 100px !important; */
  }

  & .MuiLinearProgress-bar {
    background-color: #0084b1;
    /* width: 100px !important;
    height: 100px !important; */
  }
`;

export const SelWrapper = styled.div<{ $isHover: boolean }>`
  width: 0px;
  transition: 0.5s;
  margin-bottom: 10px;

  ${props =>
    props.$isHover &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 255px;
      margin-bottom: 32px;
    `}
`;

export const SelectInputWrapper = styled.div<{ $isHover: boolean }>`
  transition: 0.5s;
  width: 85px;
  display: none;

  ${props =>
    props.$isHover &&
    css`
      display: block;
      width: 215px;
    `}
`;

export const ButtonWrapper = styled.div<{ $isHover: boolean }>`
  width: 65px;
  transition: 0.5s;

  ${props =>
    props.$isHover &&
    css`
      width: 230px;

      @media ${devices.xs} {
        width: 65px;
      }
    `}
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 30px;

  @media ${devices.xs} {
    justify-content: center;
    flex-direction: row;
    margin: 0 5px 0;
  }
`;
