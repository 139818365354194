import styled, { css } from 'styled-components';
import { devices } from '../../../constants/device';

export const VerticalMenuRoot = styled.div<{ $isHover: boolean }>`
  border-right: 0.5px solid var(--line, #ddd);
  background: #fff;
  width: 85px;
  position: absolute;
  left: 0;
  height: 100%;
  bottom: 0;
  overflow: auto;
  overflow-x: hidden;
  transition: 0.5s;

  ${props =>
    props.$isHover &&
    css`
      width: 255px;
    `}

  @media ${devices.xs} {
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    border-right: none;
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.1);
    position: sticky;
    bottom: 0;
    overflow-y: hidden;
    overflow-x: auto;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 8px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;

export const VerticalMenuContent = styled.div<{ $isNewYear: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 44px;

  @media ${devices.xs} {
    gap: 0px;
    flex-direction: row;
  }

  & .logotype {
    margin-top: ${props => (props.$isNewYear ? '0px' : '42px')};
    width: 77px;
    height: 34px;

    @media ${devices.xs} {
      display: none;
    }
  }
`;

export const ImgWrapper = styled.div<{ $isHover: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;

  .logotype {
    width: 77px;
    height: 34px;
    transition: 0.5s;

    ${props =>
      props.$isHover &&
      css`
        transition: 0.5s;
        width: 110px;
        height: 49px;
      `}
  }

  & .newyear {
    margin-top: -10px;
    width: 70px;
    height: 49px;

    ${props =>
      props.$isHover &&
      css`
        transition: 0.5s;
        margin-top: -30px;
        width: 200px;
        height: 100px;
      `};
  }
`;

export const VerticalMenuItems = styled.div<{ $isNewYear: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;

  height: ${props =>
    props.$isNewYear ? 'calc(100vh - 180px);' : 'calc(100vh - 150px);'};

  @media ${devices.xs} {
    flex-direction: row;
    justify-content: space-between;
    //gap: 70px;
    align-items: center;
  }
`;
