import { MenuItem } from '@mui/material';
import { TooltipInput, TooltipSelectInput, Dialog } from 'components';
import * as S from '../../styles';
import React, { useState } from 'react';
import { getAlphabetLetter } from '../../config';
import { FormikProps } from 'formik';
import { AddRectSVG, DeleteRedBtnSVG, SearchIconSVG } from 'assets/icons';
import { InjuryInfoFormType } from 'types/AppointmentNewTypes';
import { ChooseInjuryCode } from './ChooseInjuryCode';

type Props = {
  injuryFormik: FormikProps<Partial<InjuryInfoFormType>>;
  checkStatusCondition?: boolean;
};

export const AlphabetLines = ({ checkStatusCondition, injuryFormik }: Props) => {
  const [showCodeDialog, setShowCodeDialog] = useState<number | null>(null);

  return (
    <>
      <S.Line className="space-between">
        <S.Caption>21. Diagnosis or nature of illness or injury</S.Caption>
        <S.Line>
          <S.Caption>ICD Ind.</S.Caption>
          <S.InputWrapper className="px90">
            <TooltipInput
              value={injuryFormik.values.typeICD}
              onChange={() => undefined}
              disabled
            />
            {/*<SelectInput
              label=""
              value={injuryFormik.values.typeICD}
              onChange={e => {
                injuryFormik.setFieldValue('diagnosisCodes', []);
                injuryFormik.setFieldValue('typeICD', e.target.value);
              }}
            >
              <MenuItem value={'ICD-10'}>
                <S.MenuItemContent>ICD-10</S.MenuItemContent>
              </MenuItem>
              <MenuItem value={'ICD-9'}>
                <S.MenuItemContent>ICD-9</S.MenuItemContent>
              </MenuItem>
            </SelectInput>*/}
          </S.InputWrapper>
        </S.Line>
      </S.Line>

      {(injuryFormik.values.diagnosisCodes?.length ?? 0) < 12 &&
        injuryFormik.values.typeICD && (
          <S.Line className="end">
            <S.Btn
              onClick={
                !checkStatusCondition
                  ? () => {
                      const newCodes = Array(4).fill({
                        code: '',
                        type: injuryFormik.values.typeICD,
                      });
                      injuryFormik.setFieldValue(
                        'diagnosisCodes',
                        injuryFormik.values.diagnosisCodes?.length
                          ? [...injuryFormik.values.diagnosisCodes, ...newCodes]
                          : newCodes,
                      );
                    }
                  : undefined
              }
            >
              <AddRectSVG className="svg" />
              Add Line
            </S.Btn>
          </S.Line>
        )}
      <S.AlphabetInputs>
        {injuryFormik.values.diagnosisCodes?.map((item, index) => (
          <S.TrashInput key={index}>
            <TooltipInput
              label={getAlphabetLetter(index)}
              disabled={checkStatusCondition}
              value={
                injuryFormik.values.diagnosisCodes
                  ? injuryFormik.values.diagnosisCodes[index].code
                  : ''
              }
              onChange={e => {
                injuryFormik.setFieldValue(
                  `diagnosisCodes.${index}.code`,
                  e.target.value,
                );
              }}
              isSum={
                <S.Btn
                  onClick={
                    !checkStatusCondition ? () => setShowCodeDialog(index) : undefined
                  }
                  disabled={checkStatusCondition}
                >
                  <SearchIconSVG />
                </S.Btn>
              }
            />
            {(injuryFormik.values.diagnosisCodes?.length ?? 0) > 3 &&
              (index + 1) % 4 === 0 && (
                <DeleteRedBtnSVG
                  onClick={
                    !checkStatusCondition
                      ? () => {
                          injuryFormik.setFieldValue(
                            'diagnosisCodes',
                            injuryFormik.values.diagnosisCodes?.filter(
                              (_, i) => i < index - 3 || i >= index + 1,
                            ),
                          );
                        }
                      : undefined
                  }
                />
              )}
          </S.TrashInput>
        ))}
      </S.AlphabetInputs>

      <S.Line>
        <S.InputWrapper>
          <TooltipSelectInput
            id="resubmissionCode"
            name="resubmissionCode"
            disabled={checkStatusCondition}
            label="22. Resubmission Code"
            value={injuryFormik.values.resubmissionCode}
            onChange={e => injuryFormik.setFieldValue('resubmissionCode', e.target.value)}
          >
            <MenuItem value={'7'}>
              <S.MenuItemContent>7 - substitution</S.MenuItemContent>
            </MenuItem>
            <MenuItem value={'8'}>
              <S.MenuItemContent>8 - annulment</S.MenuItemContent>
            </MenuItem>
          </TooltipSelectInput>
        </S.InputWrapper>
        <S.InputWrapper>
          <TooltipInput
            label="Original Ref. No."
            disabled={checkStatusCondition}
            value={injuryFormik.values.originalRef}
            onChange={e => injuryFormik.setFieldValue('originalRef', e.target.value)}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <TooltipInput
            disabled={checkStatusCondition}
            label="23. Prior Authorization Number"
            value={injuryFormik.values.priorAuthorizationNumber}
            onChange={e =>
              injuryFormik.setFieldValue('priorAuthorizationNumber', e.target.value)
            }
          />
        </S.InputWrapper>
      </S.Line>

      <Dialog open={showCodeDialog !== null} onClose={() => setShowCodeDialog(null)}>
        <ChooseInjuryCode
          onApplyClick={code => {
            injuryFormik.setFieldValue(`diagnosisCodes[${showCodeDialog}].code`, code);
            setShowCodeDialog(null);
          }}
          currentValue={
            injuryFormik.values?.diagnosisCodes && showCodeDialog
              ? injuryFormik.values.diagnosisCodes[showCodeDialog].code
              : ''
          }
        />
      </Dialog>
    </>
  );
};
