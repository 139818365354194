import React from 'react';
import { TooltipInput, TooltipSelectInput } from 'components';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { StatesUSA } from 'constants/statesUSA';
import { FormikProps } from 'formik';
import { ProviderInfoFormType } from 'types/AppointmentNewTypes';
import { useClinicsGetQuery } from 'store/api/clinics/clinicApi';

import * as S from '../../styles';

type Props = {
  providerFormik: FormikProps<Partial<ProviderInfoFormType>>;
  checkStatusCondition?: boolean;
};

export const ServiceCard = ({ checkStatusCondition, providerFormik }: Props) => {
  const clinics = useClinicsGetQuery({});

  const onChangeClinic = (clinicId: string) => {
    const currentClinic = clinics.currentData?.rows?.find(i => i.id === clinicId);

    if (currentClinic) {
      void providerFormik.setValues({
        ...providerFormik.values,
        serviceFacilityName: currentClinic?.name ?? '',
        serviceFacilityId: currentClinic?.id ?? '',
        serviceStreet: currentClinic?.street ?? '',
        serviceOffice: '',
        serviceCity: currentClinic?.city ?? '',
        serviceState: currentClinic?.state ?? '',
        serviceZip: currentClinic?.zipCode ?? '',
        serviceNpi: currentClinic?.npi ?? '',
      });
    }
  };

  return (
    <S.Card>
      <S.EmptyDiv>
        <S.Caption>32. SERVICE FACILITY</S.Caption>
        <S.EmptyDiv className={'margin'}>
          <S.InputWrapper className="all">
            <TooltipSelectInput
              label="Facility Name"
              disabled={checkStatusCondition}
              value={providerFormik.values.serviceFacilityId}
              onChange={e => onChangeClinic(e.target.value as string)}
              error={!!providerFormik.errors.serviceFacilityName}
              tooltipHelperText={providerFormik.errors.serviceFacilityName}
            >
              {clinics.currentData?.rows.map(clinic => (
                <MenuItem key={clinic.id} value={clinic.id}>
                  <S.MenuItemContent>{clinic.name}</S.MenuItemContent>
                </MenuItem>
              ))}
            </TooltipSelectInput>
          </S.InputWrapper>

          <S.Line>
            <S.InputWrapper className="percent50">
              <TooltipInput
                label=""
                disabled={checkStatusCondition}
                placeholder={'Street'}
                {...providerFormik.getFieldProps('serviceStreet')}
                error={!!providerFormik.errors.serviceStreet}
                tooltipHelperText={providerFormik.errors.serviceStreet}
              />
            </S.InputWrapper>
            <S.InputWrapper className="percent50">
              <TooltipInput
                label=""
                disabled={checkStatusCondition}
                placeholder={'Office'}
                {...providerFormik.getFieldProps('serviceOffice')}
                error={!!providerFormik.errors.serviceOffice}
                tooltipHelperText={providerFormik.errors.serviceOffice}
              />
            </S.InputWrapper>
          </S.Line>

          <S.Line>
            <S.InputWrapper className="percent33">
              <TooltipInput
                label=""
                placeholder={'City'}
                disabled={checkStatusCondition}
                {...providerFormik.getFieldProps('serviceCity')}
                error={!!providerFormik.errors.serviceCity}
                tooltipHelperText={providerFormik.errors.serviceCity}
              />
            </S.InputWrapper>
            <S.InputWrapper className="percent33">
              <TooltipSelectInput
                label=""
                id="state"
                name="state"
                disabled={checkStatusCondition}
                placeholder={'State'}
                value={providerFormik.values.serviceState}
                onChange={(e: SelectChangeEvent<unknown>) =>
                  providerFormik.setFieldValue('serviceState', e.target.value)
                }
                error={!!providerFormik.errors.serviceState}
                tooltipHelperText={providerFormik.errors.serviceState}
              >
                {StatesUSA.map(state => (
                  <MenuItem key={state.abbreviation} value={state.abbreviation}>
                    <S.MenuItemContent>{state.abbreviation}</S.MenuItemContent>
                  </MenuItem>
                ))}
              </TooltipSelectInput>
            </S.InputWrapper>
            <S.InputWrapper className="percent33">
              <TooltipInput
                label=""
                disabled={checkStatusCondition}
                placeholder={'Zip'}
                {...providerFormik.getFieldProps('serviceZip')}
                error={!!providerFormik.errors.serviceZip}
                tooltipHelperText={providerFormik.errors.serviceZip}
              />
            </S.InputWrapper>
          </S.Line>

          <S.Caption>SERVICE FACILITY ID</S.Caption>
          <S.InputWrapper className="all">
            <TooltipInput
              label="NPI"
              disabled={checkStatusCondition}
              {...providerFormik.getFieldProps('serviceNpi')}
              error={!!providerFormik.errors.serviceNpi}
              tooltipHelperText={providerFormik.errors.serviceNpi}
            />
          </S.InputWrapper>
        </S.EmptyDiv>
      </S.EmptyDiv>
    </S.Card>
  );
};
