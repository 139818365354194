import React, { useEffect, useState } from 'react';
import { useLazyGetCptWithGroupsQuery } from 'store/api/claims/claimsApi';
import * as S from './styles';
import { CustomTable, RadioButton } from 'components';
import Button from 'components/atoms/Button';
import { TableCell, TableRow } from '@mui/material';

type Props = {
  onApplyClick: (code: string | null) => void;
  currentValue?: string;
  title?: string;
};

export const ChooseCptCodeWithGroup = ({
  onApplyClick,
  currentValue,
  title = 'Choose HCPCS/CPT Code',
}: Props) => {
  const [getData, statusCpt] = useLazyGetCptWithGroupsQuery({});
  const [selectedItem, setSelectedItem] = useState<string | null>(currentValue ?? null);

  useEffect(() => {
    getData({});
  }, [getData]);

  useEffect(() => {
    if (currentValue && statusCpt?.data) {
      setSelectedItem(currentValue);
    }
  }, [currentValue, statusCpt?.data]);

  return (
    <S.Content>
      <S.Article>{title}</S.Article>
      <S.Table className={'all'}>
        <CustomTable backgroundColor={'#F9F9FC'}>
          {!!statusCpt.data?.length &&
            statusCpt.data?.map(row => (
              <>
                <TableRow
                  key={row?.serviceName}
                  sx={{
                    '& .MuiTableCell-root': {
                      border: 'none',
                      textAlign: 'start',
                    },
                  }}
                >
                  <TableCell>
                    <S.Text className={'ml'}>
                      {row?.serviceName === 'otherCodes'
                        ? 'Codes without group'
                        : row?.serviceName}
                    </S.Text>
                  </TableCell>
                </TableRow>
                {row?.codes?.map(cpt => {
                  return (
                    <TableRow
                      key={cpt?.id}
                      sx={{
                        '& .MuiTableCell-root': {
                          background: selectedItem === cpt?.code ? '#e7e8ec' : '',
                          border: 'none',
                          width: '100%',
                          textAlign: 'start',
                          display: 'flex',
                          alignItems: 'center',
                          paddingBottom: '15px',
                        },
                      }}
                      onClick={e => {
                        selectedItem === cpt?.code
                          ? setSelectedItem(null)
                          : setSelectedItem(cpt.code);
                      }}
                    >
                      <TableCell>
                        <RadioButton
                          onClick={e => {
                            selectedItem === cpt?.code
                              ? setSelectedItem(null)
                              : setSelectedItem(cpt.code);
                            e.stopPropagation();
                          }}
                          checked={selectedItem === cpt?.code}
                        />
                        <S.CodeDescContainer>
                          <S.Text>{cpt?.code}</S.Text>
                          <S.Text> {cpt?.desc}</S.Text>
                        </S.CodeDescContainer>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ))}
        </CustomTable>
      </S.Table>

      <S.ButtonWrapper>
        <Button text="Apply" onClick={() => onApplyClick(selectedItem ?? '')} />
      </S.ButtonWrapper>
    </S.Content>
  );
};
