import * as S from './styles';
import DialogStatusesListRow from './TableData/DialogStatusesListRow';
import { CustomTable } from '../../../../../../../components';
import { ClaimValues } from '../../../../../../../types/AppointmentNewTypes';

type Props = {
  claimData?: ClaimValues;
};

const DialogStatusesList = ({ claimData }: Props) => {
  const headers = [
    { name: 'Category Code', key: 'CategoryCode' },
    { name: 'Date', key: 'Date' },
    { name: 'Description', key: 'Description' },
  ];

  return (
    <S.Content>
      <S.Article>Statuses List</S.Article>
      <S.TableWrapper>
        <CustomTable headers={headers}>
          <DialogStatusesListRow headers={headers} data={claimData} />
        </CustomTable>
      </S.TableWrapper>
    </S.Content>
  );
};

export default DialogStatusesList;
