import styled from 'styled-components';
import { devices } from '../../../../../../../constants/device';

export const Content = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 30px; */
  padding: 20px 30px;
  gap: 15px;
  @media ${devices.xs} {
    width: auto;
    height: auto;
    padding: 10px;
  }
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  width: 462px;
  line-height: normal;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 29px;
  }
`;

export const Span = styled.span`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;

export const Header = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 350px;
`;

export const Text = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const Div = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
