import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerMode } from '../../types/DrawerMode';
import { MessageResponse } from '../../types/ChatTypes';

type Tab = {
  tab: number;
  props: any;
};

const initialState: Tab = {
  tab: 0,
  props: null,
};

const tabSlice = createSlice({
  name: 'claimTab',
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
  },
});

const { reducer, actions } = tabSlice;

export const { setTab } = actions;
export default reducer;
