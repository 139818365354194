import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InvInfData from './InvInfData';
import { useMemo } from 'react';
import { ItemInInvoiceSheetType } from 'types/InvoiceType';
import { sortBy } from 'lodash';

type HeaderValue = {
  name: string;
  key: string;
};

type Props = {
  data: Array<ItemInInvoiceSheetType> | undefined;
  headers: Array<HeaderValue>;
  clickable?: boolean;
};

const InvInfRow = ({ headers, data, clickable }: Props) => {
  const navigate = useNavigate();

  const getNewData = useMemo(() => {
    return (data ?? []).reduce(
      (acc: ItemInInvoiceSheetType[], item) =>
        acc.some(
          i =>
            item.appointmentId &&
            i.appointmentId === item.appointmentId &&
            i.claim.claimType === item.claim.claimType,
        )
          ? [...acc, { ...item, date: null, appointment: null }]
          : [...acc, item],
      [],
    );
  }, [data]);

  return (
    <>
      {!!data?.length &&
        getNewData?.map((row: any, index) => (
          <TableRow
            key={row.id}
            className={index % 2 !== 0 ? 'notEdit grayRow' : 'notEdit'}
            onClick={() => {
              clickable && navigate('/invoices/' + row.id);
            }}
          >
            {headers.map((header, index) => (
              <TableCell
                key={header.key + index}
                className={
                  ['DOS', 'CPT', 'ChargeAmount'].includes(header.key) ? 'noPadding' : ''
                }
              >
                <InvInfData key={header.key} header={header.key} row={row} />
              </TableCell>
            ))}
          </TableRow>
        ))}
    </>
  );
};

export default InvInfRow;
